import React from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { Button, Badge } from 'antd';
import { generatePath, useNavigate } from 'react-router-dom';
import routes from 'routes';
import { useTranslation } from 'react-i18next';
import { Patient } from 'modules/Main/Patients/types';

const EXAM_TYPES = [
  { key: 'anatomyExam', link: routes.PATIENTS_ANATOMY_EXAM },
  { key: 'suckingExam', link: routes.PATIENTS_SUCKING_EXAM },
  { key: 'clinicalHistoryExam', link: routes.PATIENTS_CLINICAL_HISTORY_EXAM },
];

const EXAM_RESULTS = {
  DOUBTFUL: 'DOUBTFUL',
  NOT_EXAMINED: 'NOT_EXAMINED',
  ALTERED: 'ALTERED',
  NORMAL: 'NORMAL',
};

const shouldShowExamTags = patient => patient.anatomyExam;

type Props = {
  patient: Patient;
};

export const Title: React.FC<Props> = ({ patient: { name } }) => <IncreasedFontSize>{name}</IncreasedFontSize>;

const IncreasedFontSize = styled.span`
  font-size: 18px;
`;

const ParentName = styled.div`
  margin-bottom: 8px;
`;

export const Description: React.FC<Props> = ({ patient }) => {
  const [t] = useTranslation();

  return (
    <>
      <ParentName>
        <b>{t('patient.details.parentName')}</b>
        {patient.parentName}
      </ParentName>

      {shouldShowExamTags(patient) ? (
        <>
          <ExamDetails patient={patient} />
          <ScoreDetails patient={patient} />
        </>
      ) : (
        <span>{t('patient.details.noExamsApplied')}</span>
      )}
    </>
  );
};

const getTagColor = patient => {
  const result = pathOr(EXAM_RESULTS.NOT_EXAMINED, ['examinationResults', 'name'], patient);
  if (result === EXAM_RESULTS.DOUBTFUL) return 'orange';
  if (result === EXAM_RESULTS.ALTERED) return 'red';
  if (result === EXAM_RESULTS.NORMAL) return 'green';
  return '';
};

const ExamDetails = ({ patient }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleExamClick = link => e => {
    navigate(generatePath(link, { patientId: patient.uid }));
    e.stopPropagation();
  };

  const renderExam = ({ key, link }) =>
    patient[key] ? (
      <PatientExam key={key} size="small" onClick={handleExamClick(link)}>
        {t(`patient.exams.${key}`)}
      </PatientExam>
    ) : null;

  return (
    <div>
      <b>{t('patient.details.exams')}</b>
      {EXAM_TYPES.map(renderExam)}
    </div>
  );
};

const PatientExam = styled(Button)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

const ScoreDetails = ({ patient }) => {
  const [t] = useTranslation();
  const score = pathOr(0, ['examinationResults', 'score'], patient);
  const condition = pathOr(EXAM_RESULTS.NOT_EXAMINED, ['examinationResults', 'name'], patient);
  const color = getTagColor(patient);

  return (
    <div>
      <b>{t('patient.details.score')}</b>
      <ScoreBadge backgroundColor={color} count={score} />
      <ConditionLabel color={color}>{t(`patient.condition.${condition}`)}</ConditionLabel>
    </div>
  );
};

const ConditionLabel = styled.span`
  font-style: italic;
  color: ${({ color }) => color};
`;

const ScoreBadge = ({ count, backgroundColor }) => (
  <Badge showZero style={{ backgroundColor, marginRight: 8 }} count={count} />
);
