import React from 'react';

import ROUTER from 'routes';
import DrawerRoute from 'components/DrawerRoute';

export type DrawerProps = {
  close: () => void;
  showConfirm: (bool: boolean) => void;
  confirmClose: () => void;
};

// @todo: convert to HOC and adjust types
const PatientDrawer = ({ component: Component }) => {
  return (
    <DrawerRoute redirectRoute={ROUTER.PATIENTS}>
      <Component />
    </DrawerRoute>
  );
};

export default PatientDrawer;
