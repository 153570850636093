import { Modal } from 'antd';

const removePatientDialog = (onOk, t) => {
  Modal.confirm({
    title: t('delete-patient-dialog.title'),
    content: t('delete-patient-dialog.content'),
    okText: t('delete-patient-dialog.ok'),
    okType: 'danger',
    cancelText: t('delete-patient-dialog.cancel'),
    maskClosable: true,
    centered: true,
    onOk,
  });
};

export default removePatientDialog;
