import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import {
  Patient,
  TongueMovement,
  SuckingRhythm,
  Coordination,
  NipleChewing,
  Clicking,
} from 'modules/Main/Patients/types';
import CompleteReportHeader from '../Header';
import { Info } from './components/Info';
import { suckingExamScoreMap } from 'modules/Main/Patients/utils/score';

const styles = StyleSheet.create({
  page: {
    padding: '12 20 12 20',
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  footer: {
    fontSize: 10,
    margin: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

type SectionProps = {
  title: string;
  noPadding?: boolean;
  children: React.ReactNode;
};

const Section: React.FC<SectionProps> = ({ children, title, noPadding = false }) => {
  return (
    <View style={{ marginBottom: noPadding ? 0 : 18 }}>
      <Text style={{ marginBottom: 4, fontFamily: 'Helvetica-Bold' }}>{title}</Text>
      {children}
    </View>
  );
};

type ComponentProps = {
  children: React.ReactNode;
};

const Description: React.FC<ComponentProps> = ({ children }) => {
  return <Text style={{ marginBottom: 12 }}>{children}</Text>;
};

const Option: React.FC<ComponentProps> = ({ children }) => {
  return <Text style={{ marginBottom: 4 }}>{children}</Text>;
};

const BorderView: React.FC<ComponentProps> = ({ children }) => (
  <View style={{ border: '1px solid black', marginBottom: 12, padding: 12 }}>{children}</View>
);

type CheckboxProps = { checked?: boolean; children: React.ReactNode };

const Checkbox: React.FC<CheckboxProps> = ({ children, checked = false }) => (
  <Option>
    ( {checked ? 'X' : '  '} ) {children}
  </Option>
);

const SuckingExamPage: React.FC<{ patient: Patient }> = ({ patient }) => {
  const [t] = useTranslation();
  const { suckingExam } = patient;

  const scoresArray = ['  ', '  ', '  ', '  '];
  const score = patient?.examinationResults?.score || 0;

  if (patient.clinicalHistoryExam) {
    if (score <= 12) {
      scoresArray[2] = 'X';
    } else if (score > 12) {
      scoresArray[3] = 'X';
    }
  } else if (score <= 8) {
    scoresArray[0] = 'X';
  } else if (score > 8) {
    scoresArray[1] = 'X';
  }

  const isChecked = (optionValue: string, patientValue: string | undefined): boolean => optionValue === patientValue;

  return (
    <Page size="A4" style={styles.page}>
      <CompleteReportHeader partTitle={t('reports.suckingExam.subtitle')} />
      <BorderView>
        <Section title={t('reports.suckingExam.nonNutritive.title')} noPadding>
          <Section title={t('reports.suckingExam.nonNutritive.tongueMovement.title')} noPadding>
            <Checkbox checked={isChecked(TongueMovement.ADEQUATE, suckingExam?.tongueMovement)}>
              {t('reports.suckingExam.nonNutritive.tongueMovement.adequate')}
              {` (${suckingExamScoreMap.tongueMovement.ADEQUATE})`}
            </Checkbox>
            <Checkbox checked={isChecked(TongueMovement.INADEQUATE, suckingExam?.tongueMovement)}>
              {t('reports.suckingExam.nonNutritive.tongueMovement.inadequate')}
              {` (${suckingExamScoreMap.tongueMovement.INADEQUATE})`}
            </Checkbox>
          </Section>
        </Section>
      </BorderView>
      <BorderView>
        <Section title={t('reports.suckingExam.nutritive.title')} noPadding>
          <Description>{t('reports.suckingExam.nutritive.subtitle')}</Description>
          <Section title={t('reports.suckingExam.nutritive.suckingRhythm.title')}>
            <Checkbox checked={isChecked(SuckingRhythm.SEVERAL, suckingExam?.suckingRhythm)}>
              {t('reports.suckingExam.nutritive.suckingRhythm.several')}
              {` (${suckingExamScoreMap.suckingRhythm.SEVERAL})`}
            </Checkbox>
            <Checkbox checked={isChecked(SuckingRhythm.FEW, suckingExam?.suckingRhythm)}>
              {t('reports.suckingExam.nutritive.suckingRhythm.few')}
              {` (${suckingExamScoreMap.suckingRhythm.FEW})`}
            </Checkbox>
          </Section>

          <Section title={t('reports.suckingExam.nutritive.coordination.title')}>
            <Checkbox checked={isChecked(Coordination.ADEQUATE, suckingExam?.coordination)}>
              {t('reports.suckingExam.nutritive.coordination.adequate')}
              {` (${suckingExamScoreMap.coordination.ADEQUATE})`}
            </Checkbox>
            <Description>{t('reports.suckingExam.nutritive.coordination.adequateDescription')}</Description>
            <Checkbox checked={isChecked(Coordination.INADEQUATE, suckingExam?.coordination)}>
              {t('reports.suckingExam.nutritive.coordination.inadequate')}
              {` (${suckingExamScoreMap.coordination.INADEQUATE})`}
            </Checkbox>
            <Description>{t('reports.suckingExam.nutritive.coordination.inadequateDescription')}</Description>
          </Section>

          <Section title={t('reports.suckingExam.nutritive.nipleChewing.title')}>
            <Checkbox checked={isChecked(NipleChewing.NO, suckingExam?.nipleChewing)}>
              {t('reports.suckingExam.nutritive.nipleChewing.no')}
              {` (${suckingExamScoreMap.nipleChewing.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(NipleChewing.YES, suckingExam?.nipleChewing)}>
              {t('reports.suckingExam.nutritive.nipleChewing.yes')}
              {` (${suckingExamScoreMap.nipleChewing.YES})`}
            </Checkbox>
          </Section>

          <Section title={t('reports.suckingExam.nutritive.clicking.title')} noPadding>
            <Checkbox checked={isChecked(Clicking.NO, suckingExam?.clicking)}>
              {t('reports.suckingExam.nutritive.clicking.no')}
              {` (${suckingExamScoreMap.clicking.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(Clicking.YES, suckingExam?.clicking)}>
              {t('reports.suckingExam.nutritive.clicking.yes')}
              {` (${suckingExamScoreMap.clicking.YES})`}
            </Checkbox>
          </Section>
        </Section>
        <Text style={{ marginTop: 12 }}>{t('reports.suckingExam.results')}</Text>
      </BorderView>

      <Text>{t('reports.totalScore')}</Text>

      <Info>
        <Text>{t('reports.finalResults.line1')}</Text>
        <Text>{`${t('reports.finalResults.line2')} ( ${scoresArray[0]} )`}</Text>
        <Text>{`${t('reports.finalResults.line3')} ( ${scoresArray[1]} )`}</Text>
        <Text style={{ marginBottom: 8 }}>{t('reports.finalResults.line4')}</Text>
        <Text>{t('reports.finalResults.line5')}</Text>
        <Text>{`${t('reports.finalResults.line6')} ( ${scoresArray[2]} )`}</Text>
        <Text>{`${t('reports.finalResults.line7')} ( ${scoresArray[3]} )`}</Text>
        <Text>{t('reports.finalResults.line8')}</Text>
      </Info>

      <View
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text
          style={{
            marginBottom: 2,
            borderBottom: '1px solid black',
            width: 175,
          }}
        />
        <Text>{t('reports.complete.signature')}</Text>
      </View>

      <View style={styles.footer} fixed>
        <Text>{t('reports.footer.copyright')}</Text>
      </View>
    </Page>
  );
};

export default SuckingExamPage;
