import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Checkbox, Form, message } from 'antd';
import styled from 'styled-components';
import { ApiErrorLogger } from 'services/ApiErrorLogger';
import { signInWithGoogle } from 'services/Authentication';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import PrivacyAndTerms from 'components/PrivacyAndTerms';
import GoogleButton from 'react-google-button';

const CenterParagraph = styled.p`
  text-align: center;
  margin-bottom: 24px;
`;

const GoogleButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-disabled='true'] {
    opacity: 70%;
  }
`;

const AlertContainer = styled.div`
  margin-top: 16px;
`;

interface Props {
  label: string;
}

const GoogleSignIn: React.FC<Props> = ({ label }) => {
  const [hasAccepted, setHasAccepted] = useState(false);
  const [showError, setShowError] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();

  const signInWithGoogleHandler = async () => {
    try {
      if (!hasAccepted) {
        setShowError(true);
        return;
      }

      await signInWithGoogle();
      navigate(routes.SIGNIN);
    } catch (error) {
      message.error(t('error.unknown'));
      ApiErrorLogger(error as Error);
    }
  };

  const onChange = () => {
    setHasAccepted(state => !state);
    setShowError(false);
  };

  return (
    <>
      <CenterParagraph>{t('signin.form.or')}</CenterParagraph>
      <Form.Item
        help={showError ? t('validation.terms-and-conditions') : ''}
        validateStatus={showError ? 'error' : 'success'}
      >
        <Checkbox onChange={onChange} checked={hasAccepted}>
          <PrivacyAndTerms />
        </Checkbox>
        {!showError && !hasAccepted && (
          <AlertContainer>
            <Alert message={t('validation.terms-and-conditions')} type="error" />
          </AlertContainer>
        )}
      </Form.Item>
      <Form.Item>
        <GoogleButtonContainer data-disabled={!hasAccepted}>
          <GoogleButton label={label} type="dark" onClick={signInWithGoogleHandler} />
        </GoogleButtonContainer>
      </Form.Item>
    </>
  );
};

export default GoogleSignIn;
