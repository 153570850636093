import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useFormik } from 'formik';
import { Form, Button, message } from 'antd';
import * as yup from 'yup';

import DrawerSpinner from 'components/DrawerSpinner';
import RadioGroup from 'components/Form/RadioGroup';
import { useParams } from 'react-router-dom';
import ButtonBadge from '../../components/ButtonBadge';

import { tongueMovement, suckingRhythm, coordination, nipleChewing, clicking } from './options';
import { evaluateExamScore, SUCKING_EVALUATION } from '../../utils/score';
import { useSuckingExam } from '../../services/exams';
import { SuckingExam } from '../../types';

export const getSuckingExamSchema = t =>
  yup.object().shape({
    tongueMovement: yup.string().required(t('validation.required')),
    suckingRhythm: yup.string().required(t('validation.required')),
    coordination: yup.string().required(t('validation.required')),
    nipleChewing: yup.string().required(t('validation.required')),
    clicking: yup.string().required(t('validation.required')),
  });

const MarginRight = styled.div`
  margin-right: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  background-color: white;
  padding: 8px 16px;

  z-index: 15;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
`;

const CreateSuckingExamForm = ({ close, showConfirm, confirmClose }) => {
  const [t] = useTranslation();
  const { patientId } = useParams();
  const { data: initialValues, loading, error, update: addSuckingExam } = useSuckingExam(patientId);

  const validationSchema = useMemo(() => getSuckingExamSchema(t), [t]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (exam: SuckingExam) => {
      if (!patientId) {
        return;
      }

      try {
        await addSuckingExam(patientId, exam);
        close();
        message.success(t('success.exam-add'));
      } catch (err) {
        message.error(t('error.exam-add'));
        console.error(err);
      }
    },
  });

  if (error) {
    message.error(t('error.get-patient'));
  }

  if (loading) {
    return <DrawerSpinner />;
  }

  showConfirm(formik.dirty);

  return (
    <Form onFinish={formik.handleSubmit} layout="vertical">
      <h2>{t('suckingExam.title')}</h2>

      <h3>1. {t('suckingExam.notNutritious.title')}</h3>
      <h4>1.1 {t('suckingExam.notNutritious.tongueMovement.description')}</h4>
      <RadioGroup
        name="tongueMovement"
        options={tongueMovement}
        onChange={formik.handleChange}
        value={formik.values.tongueMovement}
      />

      <h3>2. {t('suckingExam.nutritious.title')}</h3>
      <h4>2.1 {t('suckingExam.nutritious.suckingRhythm.description')}</h4>
      <RadioGroup
        name="suckingRhythm"
        options={suckingRhythm}
        onChange={formik.handleChange}
        value={formik.values.suckingRhythm}
      />

      <h4>2.2 {t('suckingExam.nutritious.coordination.description')}</h4>
      <RadioGroup
        name="coordination"
        options={coordination}
        onChange={formik.handleChange}
        value={formik.values.coordination}
      />

      <h4>2.3 {t('suckingExam.nutritious.nippleChewing.description')}</h4>
      <RadioGroup
        name="nipleChewing"
        options={nipleChewing}
        onChange={formik.handleChange}
        value={formik.values.nipleChewing}
      />

      <h4>2.4 {t('suckingExam.nutritious.clicking.description')}</h4>
      <RadioGroup name="clicking" options={clicking} onChange={formik.handleChange} value={formik.values.clicking} />

      <ButtonsContainer>
        <MarginRight>
          <Button type="default" onClick={confirmClose}>
            {t('suckingExam.cancel')}
          </Button>
        </MarginRight>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
        >
          {t('suckingExam.save')}
          <ButtonBadge showZero count={evaluateExamScore(SUCKING_EVALUATION, formik.values)} />
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default CreateSuckingExamForm;
