import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { message } from 'antd';

import ROUTES from 'routes';
import Spinner from 'components/Spinner';
import { authContext, useAuth } from 'services/Authentication';
import PrivateRoute from 'components/PrivateRoute';
import SubscriberRoute from 'components/SubscriberRoute';
import SignIn from 'modules/SignIn';
import SignUp from 'modules/SignUp';
import ForgotPassword from 'modules/ForgotPassword';
import Main from 'modules/Main';
import Payment from 'modules/Payment';
import VerifyEmail from 'modules/VerifyEmail';

import { createGlobalStyle } from 'styled-components';
import Account from 'modules/Main/Account';
import PatientDrawer from 'modules/Main/Patients/components/PatientDrawer';
import { Actions, AnatomyExam, ClinicalHistoryExam, Patient, SuckingExam } from 'modules/Main/Patients/drawers';
import PatientsList from 'modules/Main/Patients/pages';

const GlobalStyle = createGlobalStyle`
  #root {
    background-color: #f0f2f5;
    height: 100%;
    min-height: 100%;
  }
`;

const AuthProvider = authContext.Provider;

message.config({
  maxCount: 1,
});

const App: React.FC = () => {
  const [auth, loading] = useAuth();

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthProvider value={{ auth }}>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path={ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={ROUTES.SIGNIN} element={<SignIn />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route
            path={ROUTES.PAYMENT}
            element={
              <PrivateRoute>
                <Payment />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.VERIFY_EMAIL}
            element={
              <PrivateRoute>
                <VerifyEmail />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.MAIN}
            element={
              <SubscriberRoute>
                <Main />
              </SubscriberRoute>
            }
          >
            <Route path={ROUTES.PATIENTS} element={<PatientsList />}>
              <Route path={ROUTES.PATIENTS_ADD} element={<PatientDrawer component={Patient} />} />
              <Route path={ROUTES.PATIENT_DRAWER} element={<PatientDrawer component={Actions} />} />
              <Route path={ROUTES.PATIENTS_EDIT} element={<PatientDrawer component={Patient} />} />
              <Route path={ROUTES.PATIENTS_ANATOMY_EXAM} element={<PatientDrawer component={AnatomyExam} />} />
              <Route path={ROUTES.PATIENTS_SUCKING_EXAM} element={<PatientDrawer component={SuckingExam} />} />
              <Route
                path={ROUTES.PATIENTS_CLINICAL_HISTORY_EXAM}
                element={<PatientDrawer component={ClinicalHistoryExam} />}
              />
            </Route>
            <Route path={ROUTES.ACCOUNT} element={<Account />} />
            <Route path="/" element={<Navigate to={ROUTES.PATIENTS} />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
