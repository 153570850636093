import React from 'react';
import styled from 'styled-components';
import { Badge } from 'antd';

import closed from 'assets/lipPosture/closed.jpg';
import halfOpen from 'assets/lipPosture/half-open.jpg';
import open from 'assets/lipPosture/open.jpg';

import midline from 'assets/tonguePosture/midline.jpg';
import elevated from 'assets/tonguePosture/elevated.jpg';
import midlineLateral from 'assets/tonguePosture/midline-lateral.jpg';
import apexDown from 'assets/tonguePosture/apex-down.jpg';

import round from 'assets/apexShape/round.jpg';
import vShaped from 'assets/apexShape/v-shaped.jpg';
import heartShaped from 'assets/apexShape/heart-shaped.jpg';

import visible from 'assets/lingualFrenulum/visible.jpg';
import notVisible from 'assets/lingualFrenulum/not-visible.jpg';
import visibleManeuver from 'assets/lingualFrenulum/visible-maneuver.jpg';

import thin from 'assets/frenulumThickness/thin.jpg';
import thick from 'assets/frenulumThickness/thick.jpg';

import frenulumAttachMidline from 'assets/frenulumTongueAttach/midline.jpg';
import midlineApex from 'assets/frenulumTongueAttach/midline-apex.jpg';
import apex from 'assets/frenulumTongueAttach/apex.jpg';

import sublingualCaruncles from 'assets/frenulumFloorAttach/sublingual-caruncles.jpg';
import inferiorAlveolar from 'assets/frenulumFloorAttach/inferior-alveolar.jpg';
import { useTranslation } from 'react-i18next';

import { anatomyExamScoreMap } from '../../../utils/score';

const LabeledImageContainer = styled.div`
  img {
    width: 100%;
  }
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 4px;
  line-height: 24px;
`;

interface LabeledImageProps {
  src: string;
  alt: string;
  label: string;
  count: number;
}

const LabeledImage: React.FC<LabeledImageProps> = ({ src, alt, label, count }) => {
  const [t] = useTranslation();
  return (
    <LabeledImageContainer>
      <img src={src} alt={t(alt)} />
      <Label>
        <span>{t(label)}</span>
        <Badge showZero count={count} style={{ backgroundColor: '#40a9ff' }} />
      </Label>
    </LabeledImageContainer>
  );
};

export const lipPosture = [
  {
    value: 'CLOSED',
    name: (
      <LabeledImage
        label="exam.anatomy.lips.options.CLOSED.label"
        src={closed}
        alt="exam.anatomy.lips.options.CLOSED.alt"
        count={anatomyExamScoreMap.lipPosture.CLOSED}
      />
    ),
  },
  {
    value: 'HALF_OPEN',
    name: (
      <LabeledImage
        label="exam.anatomy.lips.options.HALF_OPEN.label"
        src={halfOpen}
        alt="exam.anatomy.lips.options.HALF_OPEN.alt"
        count={anatomyExamScoreMap.lipPosture.HALF_OPEN}
      />
    ),
  },
  {
    value: 'OPEN',
    name: (
      <LabeledImage
        label="exam.anatomy.lips.options.OPEN.label"
        src={open}
        alt="exam.anatomy.lips.options.OPEN.alt"
        count={anatomyExamScoreMap.lipPosture.OPEN}
      />
    ),
  },
];

export const tonguePosture = [
  {
    value: 'MIDLINE',
    name: (
      <LabeledImage
        label="exam.anatomy.tongue.options.MIDLINE.label"
        src={midline}
        alt="exam.anatomy.tongue.options.MIDLINE.label"
        count={anatomyExamScoreMap.tonguePosture.MIDLINE}
      />
    ),
  },
  {
    value: 'ELEVATED',
    name: (
      <LabeledImage
        label="exam.anatomy.tongue.options.ELEVATED.label"
        src={elevated}
        alt="exam.anatomy.tongue.options.ELEVATED.label"
        count={anatomyExamScoreMap.tonguePosture.ELEVATED}
      />
    ),
  },
  {
    value: 'MIDLINE_LATERAL',
    name: (
      <LabeledImage
        label="exam.anatomy.tongue.options.MIDLINE_LATERAL.label"
        src={midlineLateral}
        alt="exam.anatomy.tongue.options.MIDLINE_LATERAL.label"
        count={anatomyExamScoreMap.tonguePosture.MIDLINE_LATERAL}
      />
    ),
  },
  {
    value: 'APEX_DOWN',
    name: (
      <LabeledImage
        label="exam.anatomy.tongue.options.APEX_DOWN.label"
        src={apexDown}
        alt="exam.anatomy.tongue.options.APEX_DOWN.label"
        count={anatomyExamScoreMap.tonguePosture.APEX_DOWN}
      />
    ),
  },
];

export const apexShape = [
  {
    value: 'ROUND',
    name: (
      <LabeledImage
        label="exam.anatomy.apex.options.ROUND.label"
        src={round}
        alt="exam.anatomy.apex.options.ROUND.alt"
        count={anatomyExamScoreMap.apexShape.ROUND}
      />
    ),
  },
  {
    value: 'V_SHAPED',
    name: (
      <LabeledImage
        label="exam.anatomy.apex.options.V_SHAPED.label"
        src={vShaped}
        alt="exam.anatomy.apex.options.V_SHAPED.alt"
        count={anatomyExamScoreMap.apexShape.V_SHAPED}
      />
    ),
  },
  {
    value: 'HEART_SHAPED',
    name: (
      <LabeledImage
        label="exam.anatomy.apex.options.HEART_SHAPED.label"
        src={heartShaped}
        alt="exam.anatomy.apex.options.HEART_SHAPED.alt"
        count={anatomyExamScoreMap.apexShape.HEART_SHAPED}
      />
    ),
  },
];

export const lingualFrenulum = [
  {
    value: 'VISIBLE',
    name: (
      <LabeledImage
        label="exam.anatomy.frenulum.options.VISIBLE.label"
        src={visible}
        alt="exam.anatomy.frenulum.options.VISIBLE.alt"
        count={anatomyExamScoreMap.lingualFrenulum.VISIBLE}
      />
    ),
  },
  {
    value: 'NOT_VISIBLE',
    name: (
      <LabeledImage
        label="exam.anatomy.frenulum.options.NOT_VISIBLE.label"
        src={notVisible}
        alt="exam.anatomy.frenulum.options.NOT_VISIBLE.alt"
        count={anatomyExamScoreMap.lingualFrenulum.NOT_VISIBLE}
      />
    ),
  },
  {
    value: 'VISIBLE_MANEUVER',
    name: (
      <LabeledImage
        label="exam.anatomy.frenulum.options.VISIBLE_MANEUVER.label"
        src={visibleManeuver}
        alt="exam.anatomy.frenulum.options.VISIBLE_MANEUVER.alt"
        count={anatomyExamScoreMap.lingualFrenulum.VISIBLE_MANEUVER}
      />
    ),
  },
];

export const frenulumThickness = [
  {
    value: 'THIN',
    name: (
      <LabeledImage
        label="exam.anatomy.thickness.options.THIN.label"
        src={thin}
        alt="exam.anatomy.thickness.options.THIN.alt"
        count={anatomyExamScoreMap.frenulumThickness.THIN}
      />
    ),
  },
  {
    value: 'THICK',
    name: (
      <LabeledImage
        label="exam.anatomy.thickness.options.THICK.label"
        src={thick}
        alt="exam.anatomy.thickness.options.THICK.alt"
        count={anatomyExamScoreMap.frenulumThickness.THICK}
      />
    ),
  },
];

export const frenulumTongueAttach = [
  {
    value: 'MIDLINE',
    name: (
      <LabeledImage
        label="exam.anatomy.tongueAttach.options.MIDLINE.label"
        src={frenulumAttachMidline}
        alt="exam.anatomy.tongueAttach.options.MIDLINE.alt"
        count={anatomyExamScoreMap.frenulumTongueAttach.MIDLINE}
      />
    ),
  },
  {
    value: 'MIDLINE_APEX',
    name: (
      <LabeledImage
        label="exam.anatomy.tongueAttach.options.MIDLINE_APEX.label"
        src={midlineApex}
        alt="exam.anatomy.tongueAttach.options.MIDLINE_APEX.alt"
        count={anatomyExamScoreMap.frenulumTongueAttach.MIDLINE_APEX}
      />
    ),
  },
  {
    value: 'APEX',
    name: (
      <LabeledImage
        label="exam.anatomy.tongueAttach.options.APEX.label"
        src={apex}
        alt="exam.anatomy.tongueAttach.options.APEX.alt"
        count={anatomyExamScoreMap.frenulumTongueAttach.APEX}
      />
    ),
  },
];

export const frenulumFloorAttach = [
  {
    value: 'SUBLINGUAL_CARUNCLES',
    name: (
      <LabeledImage
        label="exam.anatomy.floorAttach.options.SUBLINGUAL_CARUNCLES.label"
        src={sublingualCaruncles}
        alt="exam.anatomy.floorAttach.options.SUBLINGUAL_CARUNCLES.alt"
        count={anatomyExamScoreMap.frenulumFloorAttach.SUBLINGUAL_CARUNCLES}
      />
    ),
  },
  {
    value: 'INFERIOR_ALVEOLAR',
    name: (
      <LabeledImage
        label="exam.anatomy.floorAttach.options.INFERIOR_ALVEOLAR.label"
        src={inferiorAlveolar}
        alt="exam.anatomy.floorAttach.options.INFERIOR_ALVEOLAR.alt"
        count={anatomyExamScoreMap.frenulumFloorAttach.INFERIOR_ALVEOLAR}
      />
    ),
  },
];
