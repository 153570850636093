import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Badge } from 'antd';
import { clinicalHistoryExamScoreMap } from '../../utils/score';

const Description = styled.div`
  display: inline-block;
  white-space: normal;
`;

const RadioItem = ({ name, count }) => {
  const [t] = useTranslation();

  return (
    <Description>
      {t(name)} <Badge showZero count={count} style={{ backgroundColor: '#40a9ff' }} />
    </Description>
  );
};

export const alteredFrenulum = [
  {
    value: 'YES',
    name: (
      <RadioItem
        name="clinicalHistoryExam.familyHistory.alteredFrenulum.yes"
        count={clinicalHistoryExamScoreMap.alteredFrenulum.YES}
      />
    ),
  },
  {
    value: 'NO',
    name: (
      <RadioItem
        name="clinicalHistoryExam.familyHistory.alteredFrenulum.no"
        count={clinicalHistoryExamScoreMap.alteredFrenulum.NO}
      />
    ),
  },
];

export const feedingInterval = [
  {
    value: 'TWO_MORE',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.interval.twoOrMore"
        count={clinicalHistoryExamScoreMap.feedingInterval.TWO_MORE}
      />
    ),
  },
  {
    value: 'ONE_LESS',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.interval.oneOrLess"
        count={clinicalHistoryExamScoreMap.feedingInterval.ONE_LESS}
      />
    ),
  },
];

export const feedingFatigue = [
  {
    value: 'YES',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.fatigue.yes"
        count={clinicalHistoryExamScoreMap.feedingFatigue.YES}
      />
    ),
  },
  {
    value: 'NO',
    name: (
      <RadioItem name="clinicalHistoryExam.feeding.fatigue.no" count={clinicalHistoryExamScoreMap.feedingFatigue.NO} />
    ),
  },
];

export const suckAndSleep = [
  {
    value: 'YES',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.suckAndSleep.yes"
        count={clinicalHistoryExamScoreMap.suckAndSleep.YES}
      />
    ),
  },
  {
    value: 'NO',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.suckAndSleep.no"
        count={clinicalHistoryExamScoreMap.suckAndSleep.NO}
      />
    ),
  },
];

export const slipsOffNipple = [
  {
    value: 'YES',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.slipsOffNipple.yes"
        count={clinicalHistoryExamScoreMap.slipsOffNipple.YES}
      />
    ),
  },
  {
    value: 'NO',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.slipsOffNipple.no"
        count={clinicalHistoryExamScoreMap.slipsOffNipple.NO}
      />
    ),
  },
];

export const chewsNipple = [
  {
    value: 'YES',
    name: (
      <RadioItem
        name="clinicalHistoryExam.feeding.chewsNipple.yes"
        count={clinicalHistoryExamScoreMap.chewsNipple.YES}
      />
    ),
  },
  {
    value: 'NO',
    name: (
      <RadioItem name="clinicalHistoryExam.feeding.chewsNipple.no" count={clinicalHistoryExamScoreMap.chewsNipple.NO} />
    ),
  },
];
