export const ANATOMO_FUNCTIONAL = 'ANATOMO_FUNCTIONAL';
export const SUCKING_EVALUATION = 'SUCKING_EVALUATION';
export const CLINICAL_HISTORY = 'CLINICAL_HISTORY';

export const anatomyExamScoreMap = {
  lipPosture: {
    CLOSED: 0,
    HALF_OPEN: 1,
    OPEN: 1,
  },
  tonguePosture: {
    MIDLINE: 0,
    ELEVATED: 0,
    MIDLINE_LATERAL: 2,
    APEX_DOWN: 2,
  },
  apexShape: {
    ROUND: 0,
    V_SHAPED: 2,
    HEART_SHAPED: 3,
  },
  lingualFrenulum: {
    VISIBLE: 0,
    NOT_VISIBLE: 0,
    VISIBLE_MANEUVER: 0,
  },
  frenulumThickness: {
    THIN: 0,
    THICK: 2,
  },
  frenulumTongueAttach: {
    MIDLINE: 0,
    MIDLINE_APEX: 2,
    APEX: 3,
  },
  frenulumFloorAttach: {
    SUBLINGUAL_CARUNCLES: 0,
    INFERIOR_ALVEOLAR: 1,
  },
};

export const clinicalHistoryExamScoreMap = {
  alteredFrenulum: {
    NO: 0,
    YES: 1,
  },
  feedingInterval: {
    TWO_MORE: 0,
    ONE_LESS: 2,
  },
  feedingFatigue: {
    NO: 0,
    YES: 1,
  },
  suckAndSleep: {
    NO: 0,
    YES: 1,
  },
  slipsOffNipple: {
    NO: 0,
    YES: 1,
  },
  chewsNipple: {
    NO: 0,
    YES: 2,
  },
};

export const suckingExamScoreMap = {
  tongueMovement: {
    ADEQUATE: 0,
    INADEQUATE: 1,
  },
  suckingRhythm: {
    SEVERAL: 0,
    FEW: 1,
  },
  coordination: {
    ADEQUATE: 0,
    INADEQUATE: 1,
  },
  nipleChewing: {
    NO: 0,
    YES: 1,
  },
  clicking: {
    NO: 0,
    YES: 1,
  },
};

export const evaluateScore = (examMap, entries) => {
  return Object.keys(entries)
    .map(entry => {
      const examEntry = examMap[entry];
      return examEntry ? examEntry[entries[entry]] : null;
    })
    .filter(score => !!score)
    .reduce((acc, score) => acc + score, 0);
};

export const evaluateExamScore = (exam, data) => {
  let score = 0;
  switch (exam) {
    case ANATOMO_FUNCTIONAL:
      score = evaluateScore(anatomyExamScoreMap, data);
      break;
    case SUCKING_EVALUATION:
      score = evaluateScore(suckingExamScoreMap, data);
      break;
    case CLINICAL_HISTORY:
      score = evaluateScore(clinicalHistoryExamScoreMap, data);
      break;
    default:
      break;
  }
  return score;
};
