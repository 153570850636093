import { initializeApp } from 'firebase/app';

let config = {
  apiKey: 'AIzaSyC3V8vLOK69ZBIZovOQU6XAcJNFvsfvxeQ',
  authDomain: 'tongue-tie.web.app',
  databaseURL: 'https://tonguetie-ac9a9.firebaseio.com',
  projectId: 'tonguetie-ac9a9',
  storageBucket: 'tonguetie-ac9a9.appspot.com',
  messagingSenderId: '608725259683',
  appId: '1:608725259683:web:c869ac2648d3abc2',
};

if (process.env.REACT_APP_ENV === 'development') {
  config = {
    apiKey: 'AIzaSyCnYHT8ecCf0tvB2gjNh5bosKMSD49OHmQ',
    authDomain: 'tongue-tie-dev.firebaseapp.com',
    databaseURL: 'https://tongue-tie-dev.firebaseio.com',
    projectId: 'tongue-tie-dev',
    storageBucket: 'tongue-tie-dev.appspot.com',
    messagingSenderId: '280498676763',
    appId: '1:280498676763:web:cbd8f129a7d53e698ac4be',
  };
}

const firebase = initializeApp(config);

export default firebase;
