import React, { useMemo } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { LOCALES, ptRegex, esRegex } from 'constants/index';

const LanguageSelector: React.FC = () => {
  const [, i18n] = useTranslation();

  const handleChange = (value: string): void => {
    i18n.changeLanguage(value);
  };

  const defaultLanguage = useMemo(() => {
    if (ptRegex.test(i18n.language)) {
      return LOCALES.PT_BR;
    }
    if (esRegex.test(i18n.language)) {
      return LOCALES.ES;
    }
    return LOCALES.EN;
  }, [i18n.language]);

  return (
    <Select defaultValue={defaultLanguage} onChange={handleChange} showArrow={false} style={{ paddingLeft: '0.3rem' }}>
      <Select.Option value={LOCALES.PT_BR}>
        <span role="img" aria-label="Português (Brasil)">
          🇧🇷
        </span>
      </Select.Option>
      <Select.Option value={LOCALES.EN}>
        <span role="img" aria-label="English (US)">
          🇺🇸
        </span>
      </Select.Option>
      <Select.Option value={LOCALES.ES}>
        <span role="img" aria-label="Español">
          🇪🇸
        </span>
      </Select.Option>
    </Select>
  );
};

export default LanguageSelector;
