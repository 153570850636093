import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSession } from 'services/Authentication';
import ROUTES from 'routes';

type Props = {
  children: React.ReactElement;
  redirectTo?: string;
};

const PrivateRoute: React.FC<Props> = ({ children, redirectTo = ROUTES.SIGNIN }) => {
  const { auth } = useSession();
  const location = useLocation();

  if (!auth) {
    return (
      <Navigate
        to={{
          pathname: redirectTo,
        }}
        state={{ from: location }}
      />
    );
  }

  return children;
};

export default PrivateRoute;
