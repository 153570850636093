import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty, pathOr, propOr } from 'ramda';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clearApolloObject } from 'services/Apollo';
import { Patient, PatientData } from '../../types';
import { ADD_PATIENT, GET_PATIENT_INFO, GET_PATIENTS, REMOVE_PATIENT, UPDATE_PATIENT, GET_PATIENT } from './graphql';

const PAGE_SIZE = 20;

const transform = (data?: PatientData) => {
  const patient = clearApolloObject(propOr({}, 'patient', data));
  if (isEmpty(patient)) {
    return { address: {}, contact: {}, birth: moment().toISOString() };
  }
  return { address: {}, contact: {}, ...patient };
};

export const usePatients = () => {
  const {
    data,
    loading,
    fetchMore: _fetchMore,
    refetch: _refetch,
  } = useQuery(GET_PATIENTS, { notifyOnNetworkStatusChange: true });
  const [page, setPage] = useState(0);
  const [hasMorePatients, setHasMorePatients] = useState(true);

  useEffect(() => {
    if (data && data.length < PAGE_SIZE) {
      setHasMorePatients(false);
    }
  }, [data, setHasMorePatients]);

  const refetch = useCallback(async (filterBy: string, term: string) => _refetch({ filterBy, term }), [_refetch]);

  const fetchMore = useCallback(() => {
    const nextPage = page + 1;
    _fetchMore({
      query: GET_PATIENTS,
      variables: { offset: nextPage * PAGE_SIZE },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newPatients = pathOr([], ['patients'], fetchMoreResult);
        setHasMorePatients(newPatients.length === PAGE_SIZE);
        setPage(nextPage);
        return {
          patients: [...previousResult.patients, ...newPatients],
        };
      },
    });
  }, [page, setHasMorePatients, setPage, _fetchMore]);

  return { data: data ? data.patients : [], loading, fetchMore, refetch, hasMorePatients };
};

export const usePatientManager = () => {
  const [_create] = useMutation(ADD_PATIENT, {
    refetchQueries: ['PatientsGetPatientList'],
  });
  const create = useCallback((patient: Patient) => _create({ variables: { patient } }), [_create]);

  const [_update] = useMutation(UPDATE_PATIENT, {
    refetchQueries: ['PatientsGetPatientList'],
  });
  const update = useCallback((id: string, patient: Patient) => _update({ variables: { id, patient } }), [_update]);

  const [_remove] = useMutation(REMOVE_PATIENT, {
    refetchQueries: ['PatientsGetPatientList'],
  });
  const remove = useCallback((id: string) => _remove({ variables: { id } }), [_remove]);

  return { create, update, remove };
};

export const usePatient = (patientId?: string) => {
  const { data, loading, error } = useQuery(GET_PATIENT_INFO, {
    skip: !patientId,
    variables: { id: patientId },
    fetchPolicy: 'network-only',
  });
  return useMemo(() => ({ data: transform(data), loading, error }), [data, loading, error]);
};

export const usePatientAndExams = (patientId?: string) => {
  const { data, loading, error } = useQuery(GET_PATIENT, {
    skip: !patientId,
    variables: { id: patientId },
    fetchPolicy: 'network-only',
  });

  return useMemo(() => ({ data: transform(data), loading, error }), [data, loading, error]);
};
