import { gql } from '@apollo/client';

export const BILLING_INFO = gql`
  query BillingInfo {
    billingInfo {
      upcomingAmount
      brand
      last4
      amount
      currency
      active
      currentPeriodEnd
      cancelAtPeriodEnd
    }
  }
`;
