import { gql } from '@apollo/client';

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($cancel: Boolean) {
    updateSubscription(cancel: $cancel)
  }
`;

export const CREATE_UPDATE_SESSION = gql`
  mutation CreateEditPaymentSession {
    createUpdateSession {
      id
      customer
    }
  }
`;

export const BILLING_INFO = gql`
  query BillingInfo {
    billingInfo {
      upcomingAmount
      brand
      last4
      amount
      currency
      active
      currentPeriodEnd
      cancelAtPeriodEnd
    }
  }
`;
