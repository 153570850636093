import moment from 'moment';

export const formatUnixDate = (date: number, format: string) => moment.unix(date).format(format);

export const formatDate = (date: moment.Moment | null) => (date ? date.toISOString() : moment().toISOString());

export const parse = (date: string) => moment(date);

export const isFutureDate = (current: moment.Moment) => {
  return current && current > moment().endOf('day');
};
