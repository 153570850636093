import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, message, Card, Row, Col, Result, Input } from 'antd';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ROUTES from 'routes';
import { sendPasswordResetEmail } from 'services/Authentication';

import background from 'assets/background.jpg';
import LanguageHeader from 'components/LanguageHeader';
import { handleErrorMessage } from 'utils/formik';

function getForgotPasswordSchema(t: (k: string) => string) {
  return object().shape({
    email: string().email(t('validation.email')).required(t('validation.required')),
  });
}

const CenteredRow = styled(Row)`
  min-height: 100vh;
  background-color: #1890ff;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
`;

const ForgotPassword: React.FC = () => {
  const [t] = useTranslation();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const initialValues = { email: '' };
  const validationSchema = useMemo(() => getForgotPasswordSchema(t), [t]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async values => {
      try {
        await sendPasswordResetEmail(values.email);
        setIsEmailSent(true);
      } catch {
        message.error(t('forgotPassword.error.sendEmail'));
      }
    },
  });

  return (
    <CenteredRow justify="center">
      <Col xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <Card title={<LanguageHeader />}>
          {isEmailSent ? (
            <Result status="success" subTitle={t('forgotPassword.instructions')} />
          ) : (
            <Form onFinish={formik.handleSubmit} layout="vertical">
              <p>{t('forgotPassword.description')}</p>
              <Form.Item label={t('forgotPassword.form.email')} {...handleErrorMessage('email', formik)}>
                <Input
                  name="email"
                  placeholder={t('forgotPassword.placeholder.email')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={!formik.isValid || !formik.dirty}
                  loading={formik.isSubmitting}
                >
                  {t('forgotPassword.form.submit')}
                </Button>
              </Form.Item>
            </Form>
          )}
          <Link to={ROUTES.SIGNIN}>
            <Button block type="ghost" htmlType="button">
              {t('forgotPassword.back')}
            </Button>
          </Link>
        </Card>
      </Col>
    </CenteredRow>
  );
};

export default ForgotPassword;
