import { Badge } from 'antd';
import styled from 'styled-components';

const ButtonBadge = styled(Badge)`
  .ant-badge-count {
    margin-left: 8px;
    background-color: #1890ff;
  }
`;

export default ButtonBadge;
