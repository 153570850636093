export enum AlteredFrenulum {
  YES = 'YES',
  NO = 'NO',
}

export enum ApexShape {
  ROUND = 'ROUND',
  V_SHAPED = 'V_SHAPED',
  HEART_SHAPED = 'HEART_SHAPED',
}

export enum ChewsNipple {
  YES = 'YES',
  NO = 'NO',
}

export enum Clicking {
  YES = 'YES',
  NO = 'NO',
}

export enum Coordination {
  ADEQUATE = 'ADEQUATE',
  INADEQUATE = 'INADEQUATE',
}

export enum CouponDuration {
  once = 'once',
  repeating = 'repeating',
  forever = 'forever',
}

export enum FeedingFatigue {
  YES = 'YES',
  NO = 'NO',
}

export enum FeedingInterval {
  TWO_MORE = 'TWO_MORE',
  ONE_LESS = 'ONE_LESS',
}

export enum FilterOptions {
  name = 'name',
  parentName = 'parentName',
  parentNationalId = 'parentNationalId',
}

export enum FrenulumFloorAttach {
  SUBLINGUAL_CARUNCLES = 'SUBLINGUAL_CARUNCLES',
  INFERIOR_ALVEOLAR = 'INFERIOR_ALVEOLAR',
}

export enum FrenulumThickness {
  THIN = 'THIN',
  THICK = 'THICK',
}

export enum FrenulumTongueAttach {
  MIDLINE = 'MIDLINE',
  MIDLINE_APEX = 'MIDLINE_APEX',
  APEX = 'APEX',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum LingualFrenulum {
  VISIBLE = 'VISIBLE',
  NOT_VISIBLE = 'NOT_VISIBLE',
  VISIBLE_MANEUVER = 'VISIBLE_MANEUVER',
}

export enum LipPosture {
  CLOSED = 'CLOSED',
  HALF_OPEN = 'HALF_OPEN',
  OPEN = 'OPEN',
}

export enum NipleChewing {
  YES = 'YES',
  NO = 'NO',
}

export enum ResultType {
  NOT_EXAMINED = 'NOT_EXAMINED',
  NORMAL = 'NORMAL',
  ALTERED = 'ALTERED',
  DOUBTFUL = 'DOUBTFUL',
}

export enum SlipsOffNipple {
  YES = 'YES',
  NO = 'NO',
}

export enum SortOptions {
  name = 'name',
  parentName = 'parentName',
  parentNationalId = 'parentNationalId',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SuckAndSleep {
  YES = 'YES',
  NO = 'NO',
}

export enum SuckingRhythm {
  SEVERAL = 'SEVERAL',
  FEW = 'FEW',
}

export enum TongueMovement {
  ADEQUATE = 'ADEQUATE',
  INADEQUATE = 'INADEQUATE',
}

export enum TonguePosture {
  MIDLINE = 'MIDLINE',
  ELEVATED = 'ELEVATED',
  MIDLINE_LATERAL = 'MIDLINE_LATERAL',
  APEX_DOWN = 'APEX_DOWN',
}

export interface AddressInput {
  street?: string;
  city?: string;
  number?: string;
  state?: string;
  zip?: string;
}

export interface AlterationInput {
  who?: string;
  what?: string;
}

export interface AnatomyExamInput {
  lipPosture: LipPosture;
  tonguePosture: TonguePosture;
  apexShape: ApexShape;
  lingualFrenulum: LingualFrenulum;
  frenulumThickness?: FrenulumThickness;
  frenulumTongueAttach?: FrenulumTongueAttach;
  frenulumFloorAttach?: FrenulumFloorAttach;
}

export interface ClinicalHistoryExamInput {
  alteredFrenulum: AlteredFrenulum;
  feedingInterval: FeedingInterval;
  feedingFatigue: FeedingFatigue;
  suckAndSleep: SuckAndSleep;
  slipsOffNipple: SlipsOffNipple;
  chewsNipple: ChewsNipple;
  familyHistory?: FamilyHistoryInput;
  healthIssues?: string[];
}

export interface ContactInput {
  primaryPhone?: string;
  alternativePhone?: string;
  email: string;
}

export interface FamilyHistoryInput {
  alterations?: AlterationInput[];
  issues?: string[];
}

export interface PatientInput {
  name?: string;
  parentNationalId?: string;
  parentName: string;
  birth: string;
  gender: Gender;
  contact?: ContactInput;
  address?: AddressInput;
}

export interface SuckingExamInput {
  tongueMovement: TongueMovement;
  suckingRhythm: SuckingRhythm;
  coordination: Coordination;
  nipleChewing: NipleChewing;
  clicking: Clicking;
}

export interface Address {
  street?: string;
  city?: string;
  number?: string;
  state?: string;
  zip?: string;
}

export interface Alteration {
  who?: string;
  what?: string;
}

export interface AnatomyExam {
  score?: number;
  lipPosture: LipPosture;
  tonguePosture: TonguePosture;
  apexShape: ApexShape;
  lingualFrenulum: LingualFrenulum;
  frenulumThickness?: FrenulumThickness;
  frenulumTongueAttach?: FrenulumTongueAttach;
  frenulumFloorAttach?: FrenulumFloorAttach;
  lastModified?: string;
}

export interface BillingInfo {
  brand?: string;
  last4?: string;
  amount?: number;
  currency?: string;
  active?: boolean;
  currentPeriodEnd?: number;
  cancelAtPeriodEnd?: boolean;
  upcomingAmount?: number;
}

export interface ClinicalHistoryExam {
  score?: number;
  alteredFrenulum: AlteredFrenulum;
  feedingInterval: FeedingInterval;
  feedingFatigue: FeedingFatigue;
  suckAndSleep: SuckAndSleep;
  slipsOffNipple: SlipsOffNipple;
  chewsNipple: ChewsNipple;
  familyHistory?: FamilyHistory;
  lastModified?: string;
  healthIssues?: string[];
}

export interface Contact {
  primaryPhone?: string;
  alternativePhone?: string;
  email: string;
}

export interface ExaminationResults {
  name?: ResultType;
  score?: number;
}

export interface FamilyHistory {
  alterations?: Alteration[];
  issues?: string[];
}

export interface Patient {
  uid?: string;
  ownerId?: string;
  name?: string;
  parentNationalId?: string;
  parentName: string;
  birth: string;
  gender: Gender;
  address?: Address;
  contact: Contact;
  anatomyExam?: AnatomyExam;
  clinicalHistoryExam?: ClinicalHistoryExam;
  suckingExam?: SuckingExam;
  examinationResults?: ExaminationResults;
}

export interface SuckingExam {
  score?: number;
  tongueMovement: TongueMovement;
  suckingRhythm: SuckingRhythm;
  coordination: Coordination;
  nipleChewing: NipleChewing;
  clicking: Clicking;
  lastModified?: string;
}

export enum EXAM {
  ANATOMY_EXAM = 'ANATOMY_EXAM',
  SUCKING_EXAM = 'SUCKING_EXAM',
  CLINICAL_HISTORY_EXAM = 'CLINICAL_HISTORY_EXAM',
}

export enum Report {
  SCREENING = 'SCREENING',
  COMPLETE_PROTOCOL = 'COMPLETE_PROTOCOL',
}

export type PatientData = {
  patient: Patient;
};
