import { FormItemProps } from 'antd';
import { FormikHelpers, FormikState } from 'formik';
import { path } from 'ramda';
import { formatDate } from './date';

export function handleInputChange<K extends string, T extends { [P in K]?: unknown }>(
  field: K,
  formik: FormikHelpers<T>
) {
  return (evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(field, evt.target.value);
}

export function handleSelectChange<K extends string, T extends { [P in K]?: unknown }>(
  field: K,
  formik: FormikHelpers<T>
) {
  return (value: string) => formik.setFieldValue(field, value);
}

export function handleDatepickerChange<K extends string, T extends { [P in K]?: unknown }>(
  field: K,
  formik: FormikHelpers<T>
) {
  return (value: moment.Moment | null) => formik.setFieldValue(field, formatDate(value));
}

export function handleErrorMessage<K extends string, T extends { [P in K]?: unknown }>(
  field: K,
  formik: FormikState<T>
) {
  const propPath = field.split('.');
  const isFieldTouched = Boolean(path(propPath, formik.touched));
  const help = isFieldTouched ? path(propPath, formik.errors) : '';
  const validateStatus = help ? 'error' : '';

  return {
    help,
    validateStatus,
  } as { help: FormItemProps['help']; validateStatus: FormItemProps['validateStatus'] };
}
