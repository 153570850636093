import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type ScoreProps = {
  score?: number;
  birthDate: string;
};

export const Score: React.FC<ScoreProps> = ({ score, birthDate }) => {
  const [t] = useTranslation();

  const mark = (value: boolean): string => (value ? 'X' : ' ');

  if (score === null || score === undefined) return null;

  const normalMark = score >= 0 && score <= 4;
  const doubtfulMark = score === 5 || score === 6;
  const alteredMark = score >= 7;

  const retestDate = moment(birthDate).add(1, 'M').format(t('dateFormat'));

  return (
    <View style={{ fontFamily: 'Helvetica-Bold' }}>
      <View style={{ paddingBottom: 8 }}>
        <Text>{`${t('reports.screening.results.normal')} ( ${mark(normalMark)} )`}</Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: 8 }}>
        <Text>
          {`${t('reports.screening.results.doubtful')} ( ${mark(doubtfulMark)} ) ${
            doubtfulMark ? t('reports.screening.results.retest', { retestDate }) : ''
          }`}
        </Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', paddingBottom: 8 }}>
        <Text>
          {`${t('reports.screening.results.altered')} ( ${mark(alteredMark)} ) ${
            alteredMark ? t('reports.screening.results.recommendation') : ''
          }`}
        </Text>
      </View>
    </View>
  );
};
