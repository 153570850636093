import React from 'react';
import { View } from '@react-pdf/renderer';

interface Props {
  children: React.ReactNode;
}

export const Info: React.FC<Props> = ({ children }) => {
  return (
    <View
      style={{
        backgroundColor: 'lightgray',
        fontSize: 8,
        border: 1,
        padding: 8,
        marginTop: 8,
        marginLeft: 8,
      }}
    >
      {children}
    </View>
  );
};
