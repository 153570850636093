import React from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

interface Props {
  amountOff: string;
  percentOff: number;
  durationInMonths: number;
  currency: string;
  clearCoupon: () => void;
}

const CouponDetails: React.FC<Props> = ({ amountOff, percentOff, durationInMonths, currency, clearCoupon }) => {
  const [t] = useTranslation();
  return (
    <>
      <Text strong>
        {t('plan.coupon', {
          discount: percentOff ? `${percentOff}%` : `${t(`plan.priceDetails.${currency}`)} ${amountOff}`,
        })}
      </Text>
      {Boolean(durationInMonths) && <Text strong>{t('plan.couponDuration', { duration: durationInMonths })}</Text>}
      <Button type="link" onClick={clearCoupon}>
        {t('plan.removeCoupon')}
      </Button>
    </>
  );
};

export default CouponDetails;
