import { gql } from '@apollo/client';

export const GET_ANATOMY_EXAM = gql`
  query AnatomyExamDrawerGetExam($id: ID!) {
    patient(id: $id) {
      anatomyExam {
        lipPosture
        tonguePosture
        apexShape
        lingualFrenulum
        frenulumThickness
        frenulumTongueAttach
        frenulumFloorAttach
      }
    }
  }
`;

export const ADD_ANATOMY_EXAM = gql`
  mutation AnatomyExamDrawerAddExam($patientId: ID!, $exam: AnatomyExamInput) {
    addAnatomyExam(patientId: $patientId, exam: $exam) {
      lipPosture
      tonguePosture
      apexShape
      lingualFrenulum
      frenulumThickness
      frenulumTongueAttach
      frenulumFloorAttach
    }
  }
`;

export const GET_SUCKING_EXAM = gql`
  query GetPatientSuckingExam($id: ID!) {
    patient(id: $id) {
      suckingExam {
        tongueMovement
        suckingRhythm
        coordination
        nipleChewing
        clicking
      }
    }
  }
`;

export const ADD_SUCKING_EXAM = gql`
  mutation AddSuckingExam($patientId: ID!, $exam: SuckingExamInput) {
    addSuckingExam(patientId: $patientId, exam: $exam) {
      tongueMovement
      suckingRhythm
      coordination
      nipleChewing
      clicking
    }
  }
`;

export const GET_CLINICAL_HISTORY_EXAM = gql`
  query GetPatientClinicalHistoryExam($id: ID!) {
    patient(id: $id) {
      clinicalHistoryExam {
        alteredFrenulum
        feedingInterval
        feedingFatigue
        suckAndSleep
        slipsOffNipple
        chewsNipple
        familyHistory {
          alterations {
            who
            what
          }
        }
        healthIssues
      }
    }
  }
`;

export const ADD_CLINICAL_HISTORY_EXAM = gql`
  mutation AddClinicalExam($patientId: ID!, $exam: ClinicalHistoryExamInput) {
    addClinicalHistoryExam(patientId: $patientId, exam: $exam) {
      alteredFrenulum
      feedingInterval
      feedingFatigue
      suckAndSleep
      slipsOffNipple
      chewsNipple
    }
  }
`;

export const REMOVE_ANATOMY_EXAM = gql`
  mutation ActionDrawerRemoveAnatomyExam($patientId: ID!) {
    removeAnatomyExam(patientId: $patientId)
  }
`;

export const REMOVE_CLINICAL_HISTORY_EXAM = gql`
  mutation ActionDrawerRemoveClinicalHistoryExam($patientId: ID!) {
    removeClinicalHistoryExam(patientId: $patientId)
  }
`;

export const REMOVE_SUCKING_EXAM = gql`
  mutation ActionDrawerRemoveSuckingExam($patientId: ID!) {
    removeSuckingExam(patientId: $patientId)
  }
`;
