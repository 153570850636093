import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Patient } from '../../types';

import {
  lipPosture,
  tonguePosture,
  apexShape,
  lingualFrenulum,
  frenulumThickness,
  frenulumTongueAttach,
  frenulumFloorAttach,
} from './options';

import { Score } from './components/Score';
import { Options, Option } from './components/Options';
import { Section } from './components/Section';
import { Observation } from './components/Observation';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    padding: '12 20 12 20',
    fontSize: 10,
  },
  footer: {
    fontSize: 10,
    margin: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

type Props = {
  patient: Patient;
};

const ScreeningReport: React.FC<Props> = ({ patient }) => {
  const [t] = useTranslation();
  const { anatomyExam } = patient;

  const isChecked = (optionValue: string, patientValue: string | undefined): boolean => optionValue === patientValue;
  const fomatDate = (date: string | undefined): string => moment(date).format(t('dateFormat'));

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            textAlign: 'center',
            marginBottom: 12,
            fontFamily: 'Helvetica-Bold',
          }}
        >
          <Text>{t('reports.screening.title')}</Text>
          <Text>{t('reports.screening.subtitle')}</Text>
          <Text>{t('reports.screening.author')}</Text>
        </View>
        <View style={{ marginBottom: 12, fontFamily: 'Helvetica-Bold' }}>
          <Text>{t('reports.screening.name', { name: patient.name })}</Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={{ width: '50%' }}>
              {t('reports.screening.birthDate', {
                birthDate: fomatDate(patient.birth),
              })}
            </Text>
            <Text style={{ width: '50%' }}>
              {t('reports.screening.examDate', {
                examDate: fomatDate(patient.anatomyExam?.lastModified),
              })}
            </Text>
          </View>
        </View>
        <Section title={t('reports.anatomyExam.lipPosture.title')}>
          <Options>
            {lipPosture.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.lipPosture)}
                />
              );
            })}
          </Options>
        </Section>

        <Section title={t('reports.anatomyExam.tonguePosture.title')}>
          <Options>
            {tonguePosture.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.tonguePosture)}
                />
              );
            })}
          </Options>
        </Section>

        <Section title={t('reports.anatomyExam.apexShape.title')}>
          <Options>
            {apexShape.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.apexShape)}
                />
              );
            })}
          </Options>
        </Section>

        <Section title={t('reports.anatomyExam.lingualFrenulum.title')}>
          <Options>
            {lingualFrenulum.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.lingualFrenulum)}
                  showCount={false}
                />
              );
            })}
          </Options>
          <Observation>{t('reports.screening.observation')}</Observation>
        </Section>

        <Section title={t('reports.anatomyExam.frenulumThickness.title')}>
          <Options>
            {frenulumThickness.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.frenulumThickness)}
                />
              );
            })}
          </Options>
        </Section>

        <Section title={t('reports.anatomyExam.frenulumTongueAttach.title')}>
          <Options>
            {frenulumTongueAttach.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.frenulumTongueAttach)}
                />
              );
            })}
          </Options>
        </Section>

        <Section title={t('reports.anatomyExam.frenulumFloorAttach.title')}>
          <Options>
            {frenulumFloorAttach.map(option => {
              return (
                <Option
                  key={option.label}
                  src={option.src}
                  text={t(option.label)}
                  count={option.count}
                  checked={isChecked(option.value, anatomyExam?.frenulumFloorAttach)}
                />
              );
            })}
          </Options>
        </Section>

        <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <View style={{ width: '50%' }}>
            <Score score={anatomyExam?.score} birthDate={patient.birth} />
          </View>
          <View
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                marginBottom: 2,
                borderBottom: '1px solid black',
                width: 175,
              }}
            />
            <Text>{t('reports.complete.signature')}</Text>
          </View>
        </View>

        <View style={styles.footer} fixed>
          <Text>{t('reports.footer.copyright')}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default ScreeningReport;
