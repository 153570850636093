import React from 'react';
import { Text, View } from '@react-pdf/renderer';

type SectionProps = {
  title: string;
  children: React.ReactNode;
};

export const Section: React.FC<SectionProps> = ({ children, title }) => (
  <View>
    <View>
      <Text style={{ fontFamily: 'Helvetica-Bold', marginBottom: 4 }}>{title}</Text>
    </View>
    <View style={{ width: '100%' }}>{children}</View>
  </View>
);
