import React from 'react';
import { Badge, BadgeProps } from 'antd';
import { ExaminationResults, ResultType } from 'modules/Main/Patients/types';
import { propOr } from 'ramda';

const StatusDictionary: Record<keyof typeof ResultType, BadgeProps['status']> = {
  ALTERED: 'error',
  DOUBTFUL: 'warning',
  NORMAL: 'success',
  NOT_EXAMINED: 'default',
};

type Props = {
  status?: ExaminationResults;
};

export const ExaminationBadge: React.FC<Props> = ({ status }) => {
  const name: keyof typeof ResultType = propOr(StatusDictionary.NOT_EXAMINED, 'name')(status);
  const value = StatusDictionary[name];
  return <Badge status={value} />;
};
