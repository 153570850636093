import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Row, Col, Card, Button, Spin, message } from 'antd';
import { useInterval } from 'react-use';

import { hardReloadUser, signOut, useSession, sendEmailVerification } from 'services/Authentication';
import ROUTES from 'routes';
import Spinner from 'components/Spinner';
import { ApiErrorLogger } from 'services/ApiErrorLogger';

import background from 'assets/background.jpg';
import LanguageHeader from 'components/LanguageHeader';

const IconSpinner = <LoadingOutlined style={{ fontSize: 72 }} spin />;

const CenteredRow = styled(Row)`
  min-height: 100vh;
  background-color: #1890ff;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
`;

const NotReceivedContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
`;

const LinkButton = styled(Button)`
  &.ant-btn-link {
    padding-left: 5px;
    padding-right: 0px;
  }
`;

const MarginBlock = styled.div`
  margin-right: 16px;
`;

const CONFIRMATION_INTERVAL = 15000;

const VerifyEmail: React.FC = () => {
  const { auth } = useSession();
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [t] = useTranslation();
  const location = useLocation();

  useInterval(() => {
    hardReloadUser();
  }, CONFIRMATION_INTERVAL);

  const onConfirmClick = async (): Promise<void> => {
    try {
      setLoading(true);
      await hardReloadUser();
      setLoading(false);
    } catch (error) {
      ApiErrorLogger(error);
    }
  };

  const resendEmail = async (): Promise<void> => {
    try {
      await sendEmailVerification();
      setDisableButton(true);
      message.success(t('verifyEmail.successEmail'));
    } catch (error) {
      message.error(t('verifyEmail.failedEmail'));
      ApiErrorLogger(error);
    }
  };

  const logout = async (): Promise<void> => {
    await signOut();
  };

  if (auth?.emailVerified) {
    return <Navigate to={ROUTES.MAIN} state={{ from: location }} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <CenteredRow justify="center">
      <Col xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <Card title={<LanguageHeader />}>
          <div>{t('verifyEmail.description')}</div>
          <LoadingContainer>
            <Spin indicator={IconSpinner} />
          </LoadingContainer>
          <ButtonContainer>
            <Button type="primary" onClick={onConfirmClick}>
              {t('verifyEmail.confirm')}
            </Button>
            <MarginBlock />
            <Button danger onClick={logout}>
              {t('verifyEmail.logout')}
            </Button>
          </ButtonContainer>
          <NotReceivedContainer>
            <span>
              {t('verifyEmail.notReceived')}
              <LinkButton type="link" disabled={disableButton} onClick={resendEmail}>
                {t('verifyEmail.resend')}
              </LinkButton>
            </span>
          </NotReceivedContainer>
        </Card>
      </Col>
    </CenteredRow>
  );
};

export default VerifyEmail;
