import React, { useState } from 'react';
import styled from 'styled-components';
import { useMedia } from 'react-use';

import { Layout } from 'antd';

import Brand from 'components/Brand';

import { MenuOutlined } from '@ant-design/icons';
import SiderMenu from './components/SiderMenu';
import LanguageSelector from '../LanguageSelector';

const { Header, Sider, Content } = Layout;

const Trigger = styled(MenuOutlined)`
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;

const BrandContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-left: 24px;
  height: 64px;
`;

const ExtraSmallInnerPage = styled(Layout)`
  min-width: 100vw;
`;

const LargeInnerPage = styled(Layout)``;

const FullScreenLayout = styled(Layout)`
  .ant-layout {
    min-height: 100vh;
  }

  .ant-layout-header {
    background: #fff;
    padding: 0;
  }

  .ant-layout-content {
    margin: 24px 16px;
    padding: 24px;
    background: #fff;
    min-height: 280px;
  }

  overflow-x: hidden;
`;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LanguageContainer = styled.div`
  margin-right: 16px;
`;

const PageLayout = ({ children }) => {
  const isExtraSmall = useMedia('(max-width: 480px)');
  const [collapsed, setCollapsed] = useState(isExtraSmall);

  const width = isExtraSmall ? '60%' : '250px';

  const InnerPage = isExtraSmall ? ExtraSmallInnerPage : LargeInnerPage;

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <FullScreenLayout>
      <Sider theme="light" trigger={null} collapsible collapsedWidth={0} width={width} collapsed={collapsed}>
        <BrandContainer>
          <Brand />
        </BrandContainer>
        <SiderMenu />
      </Sider>
      <InnerPage>
        <StyledHeader>
          <Trigger className="trigger" onClick={toggle} />
          <LanguageContainer>
            <LanguageSelector />
          </LanguageContainer>
        </StyledHeader>
        <Content>{children}</Content>
      </InnerPage>
    </FullScreenLayout>
  );
};

export default PageLayout;
