import React from 'react';

type Props = {
  videoId: string;
};

const YouTube: React.FC<Props> = ({ videoId }) => {
  return (
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%',
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        title="Tongue Tie App"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
      />
    </div>
  );
};

export default YouTube;
