import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link, generatePath } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Typography, List } from 'antd';

import { Patient, EXAM } from 'modules/Main/Patients/types';

import { useAppliedExams, EXAM_META } from '../utils';
import ExaminationBox from '../ExaminationBox';

const MarginBottomBlock = styled.div`
  margin-bottom: 16px;
`;

const MarginRightBlock = styled.div`
  margin-right: 16px;
`;

interface PatientExamsProps {
  patient: Patient;
  patientId: string;
  onRemoveExam: (exam: EXAM) => () => void;
}

const PatientExams: React.FC<PatientExamsProps> = ({ patient, patientId, onRemoveExam }) => {
  const [t] = useTranslation();
  const exams = useAppliedExams(patient);

  return (
    <>
      <Typography.Title level={3}>{t('actions.exams')}</Typography.Title>
      <MarginBottomBlock>
        <ExaminationBox patient={patient} patientId={patientId} />
      </MarginBottomBlock>
      <MarginBottomBlock>
        <List
          header={<Typography.Title level={4}>{t('actions.applied-exams')}</Typography.Title>}
          dataSource={exams}
          renderItem={(item: EXAM): ReactNode => (
            <List.Item key={item}>
              <List.Item.Meta title={t(EXAM_META[item].i18nKey)} />
              <Button danger icon={<DeleteOutlined />} onClick={onRemoveExam(item)} />
              <MarginRightBlock />
              <Link to={generatePath(EXAM_META[item].link, { patientId })}>
                <Button type="default" icon={<EditOutlined />} />
              </Link>
            </List.Item>
          )}
        />
      </MarginBottomBlock>
    </>
  );
};

export default PatientExams;
