const ROUTES = {
  SIGNIN: '/signin',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  PAYMENT: '/payment',
  TERMS_AND_CONDITIONS: 'https://tonguetieapp.com/terms',
  PRIVACY_POLICIES: 'https://tonguetieapp.com/privacy-policies',

  MAIN: '/',

  ACCOUNT: '/profile',

  PATIENTS: '/patients',
  PATIENTS_ADD: '/patients/add',
  PATIENTS_EDIT: '/patients/edit/:patientId',
  PATIENT_DRAWER: '/patients/:patientId',
  PATIENTS_ANATOMY_EXAM: '/patients/:patientId/anatomy-exam',
  PATIENTS_CLINICAL_HISTORY_EXAM: '/patients/:patientId/clinical-history-exam',
  PATIENTS_SUCKING_EXAM: '/patients/:patientId/sucking-exam',
};

export default ROUTES;
