import { saveAs } from 'file-saver';

import { Patient, Report } from '../../types';
import ScreeningReport from '../../components/ScreeningReport';
import CompleteReport from '../../components/CompleteReport';

export async function downloadReport(blob: Blob, filename: string): Promise<void> {
  saveAs(blob, filename);
}

export const REPORT_META = {
  [Report.SCREENING]: { i18nKey: 'patient.reports.screening', component: ScreeningReport },
  [Report.COMPLETE_PROTOCOL]: { i18nKey: 'patient.reports.complete', component: CompleteReport },
};

export const getAvailableReports = (patient: Patient): Report[] => {
  if (patient.clinicalHistoryExam || patient.suckingExam) {
    return [Report.SCREENING, Report.COMPLETE_PROTOCOL];
  }

  if (patient.anatomyExam) {
    return [Report.SCREENING];
  }

  return [];
};
