import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { propEq, when, omit, isEmpty } from 'ramda';

import { useFormik } from 'formik';
import { Form, Button, message, Alert } from 'antd';
import * as yup from 'yup';

import { clearApolloObject } from 'services/Apollo';
import RadioGroup from 'components/Form/RadioGroup';
import DrawerSpinner from 'components/DrawerSpinner';
import { useParams } from 'react-router-dom';

import { alteredFrenulum, feedingInterval, feedingFatigue, suckAndSleep, slipsOffNipple, chewsNipple } from './options';
import { evaluateExamScore, CLINICAL_HISTORY } from '../../utils/score';
import ButtonBadge from '../../components/ButtonBadge';
import { useClinicalHistoryExam } from '../../services/exams';
import { DrawerProps } from '../../components/PatientDrawer';
import { ClinicalHistoryExam } from '../../types';
import { HealthIssues } from './components/HealthIssues';
import { Alterations } from './components/Alterations';

export const getClinicalHistorySchema = t =>
  yup.object().shape({
    alteredFrenulum: yup.string().required(t('validation.required')),
    feedingInterval: yup.string().required(t('validation.required')),
    feedingFatigue: yup.string().required(t('validation.required')),
    suckAndSleep: yup.string().required(t('validation.required')),
    slipsOffNipple: yup.string().required(t('validation.required')),
    chewsNipple: yup.string().required(t('validation.required')),
    familyHistory: yup.object().shape({
      alterations: yup
        .array()
        .of(
          yup.object().shape({
            who: yup.string(),
            what: yup.string(),
          })
        )
        .max(3),
    }),
    healthIssues: yup.array().of(yup.string()).max(3),
  });

const MarginRight = styled.div`
  margin-right: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  background-color: white;
  padding: 8px 16px;

  z-index: 15;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
`;

const ClinicalHistoryExamForm: React.FC<DrawerProps> = ({ close, showConfirm, confirmClose }) => {
  const [t] = useTranslation();
  const { patientId } = useParams();
  const { data: initialValues, loading, error, update: addClinicalHistoryExam } = useClinicalHistoryExam(patientId);

  const validationSchema = useMemo(() => getClinicalHistorySchema(t), [t]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (rawExam: ClinicalHistoryExam) => {
      if (!patientId) {
        return;
      }

      try {
        const exam = clearApolloObject(rawExam);
        const removeAlterations = when(propEq('alteredFrenulum', 'NO'), omit(['familyHistory', 'alterations']));
        // @ts-ignore
        await addClinicalHistoryExam(patientId, removeAlterations(exam));
        close();
        message.success(t('success.exam-add'));
      } catch (err) {
        message.error(t('error.exam-add'));
        console.error(err);
      }
    },
  });

  if (error) {
    message.error(t('error.get-patient'));
  }

  if (loading) {
    return <DrawerSpinner />;
  }

  showConfirm(formik.dirty);

  return (
    <Form onFinish={formik.handleSubmit} layout="vertical">
      <h2>{t('clinicalHistoryExam.title')}</h2>

      <h3>{t('clinicalHistoryExam.familyHistory.title')}</h3>
      <h4>{t('clinicalHistoryExam.familyHistory.alteredFrenulum.description')}</h4>
      <RadioGroup
        name="alteredFrenulum"
        options={alteredFrenulum}
        onChange={formik.handleChange}
        value={formik.values.alteredFrenulum}
      />

      {formik.values.alteredFrenulum === 'YES' && <Alterations formik={formik} />}

      <h4>{t('clinicalHistoryExam.healthIssues.description')}</h4>
      {formik.values.healthIssues && !isEmpty(formik.values.healthIssues) && (
        <Alert
          style={{ marginBottom: 24 }}
          message={t('clinicalHistoryExam.healthIssues.warning.title')}
          description={t('clinicalHistoryExam.healthIssues.warning.description')}
          type="warning"
          showIcon
        />
      )}
      <HealthIssues formik={formik} />

      <h3>{t('clinicalHistoryExam.feeding.title')}</h3>
      <h4>{t('clinicalHistoryExam.feeding.interval.description')}</h4>
      <RadioGroup
        name="feedingInterval"
        options={feedingInterval}
        onChange={formik.handleChange}
        value={formik.values.feedingInterval}
      />

      <h4>{t('clinicalHistoryExam.feeding.fatigue.description')}</h4>
      <RadioGroup
        name="feedingFatigue"
        options={feedingFatigue}
        onChange={formik.handleChange}
        value={formik.values.feedingFatigue}
      />

      <h4>{t('clinicalHistoryExam.feeding.suckAndSleep.description')}</h4>
      <RadioGroup
        name="suckAndSleep"
        options={suckAndSleep}
        onChange={formik.handleChange}
        value={formik.values.suckAndSleep}
      />

      <h4>{t('clinicalHistoryExam.feeding.slipsOffNipple.description')}</h4>
      <RadioGroup
        name="slipsOffNipple"
        options={slipsOffNipple}
        onChange={formik.handleChange}
        value={formik.values.slipsOffNipple}
      />

      <h4>{t('clinicalHistoryExam.feeding.chewsNipple.description')}</h4>
      <RadioGroup
        name="chewsNipple"
        options={chewsNipple}
        onChange={formik.handleChange}
        value={formik.values.chewsNipple}
      />

      <ButtonsContainer>
        <MarginRight>
          <Button type="default" onClick={confirmClose}>
            {t('suckingExam.cancel')}
          </Button>
        </MarginRight>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!formik.isValid || !formik.dirty}
          loading={formik.isSubmitting}
        >
          {t('clinicalHistoryExam.save')}
          <ButtonBadge showZero count={evaluateExamScore(CLINICAL_HISTORY, formik.values)} />
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default ClinicalHistoryExamForm;
