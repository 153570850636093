import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { Badge } from 'antd';
import { suckingExamScoreMap } from '../../utils/score';

const Description = styled.div`
  display: inline-block;
  white-space: normal;
`;

const RadioItem = ({ name, count }) => {
  const [t] = useTranslation();

  return (
    <Description>
      {t(name)} <Badge showZero count={count} style={{ backgroundColor: '#40a9ff' }} />
    </Description>
  );
};

export const tongueMovement = [
  {
    value: 'ADEQUATE',
    name: (
      <RadioItem
        name="suckingExam.notNutritious.tongueMovement.adequate"
        count={suckingExamScoreMap.tongueMovement.ADEQUATE}
      />
    ),
  },
  {
    value: 'INADEQUATE',
    name: (
      <RadioItem
        name="suckingExam.notNutritious.tongueMovement.inadequate"
        count={suckingExamScoreMap.tongueMovement.INADEQUATE}
      />
    ),
  },
];

export const suckingRhythm = [
  {
    value: 'SEVERAL',
    name: (
      <RadioItem
        name="suckingExam.nutritious.suckingRhythm.several"
        count={suckingExamScoreMap.suckingRhythm.SEVERAL}
      />
    ),
  },
  {
    value: 'FEW',
    name: <RadioItem name="suckingExam.nutritious.suckingRhythm.few" count={suckingExamScoreMap.suckingRhythm.FEW} />,
  },
];

export const coordination = [
  {
    value: 'ADEQUATE',
    name: (
      <RadioItem
        name="suckingExam.nutritious.coordination.adequate"
        count={suckingExamScoreMap.coordination.ADEQUATE}
      />
    ),
  },
  {
    value: 'INADEQUATE',
    name: (
      <RadioItem
        name="suckingExam.nutritious.coordination.inadequate"
        count={suckingExamScoreMap.coordination.INADEQUATE}
      />
    ),
  },
];

export const nipleChewing = [
  {
    value: 'YES',
    name: <RadioItem name="suckingExam.nutritious.nippleChewing.yes" count={suckingExamScoreMap.nipleChewing.YES} />,
  },
  {
    value: 'NO',
    name: <RadioItem name="suckingExam.nutritious.nippleChewing.no" count={suckingExamScoreMap.nipleChewing.NO} />,
  },
];

export const clicking = [
  {
    value: 'YES',
    name: <RadioItem name="suckingExam.nutritious.clicking.yes" count={suckingExamScoreMap.clicking.YES} />,
  },
  {
    value: 'NO',
    name: <RadioItem name="suckingExam.nutritious.clicking.no" count={suckingExamScoreMap.clicking.NO} />,
  },
];
