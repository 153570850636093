import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 100vh;
  vertical-align: middle;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
`;

const Spinner: React.FC = () => (
  <SpinnerWrapper>
    <Spin size="large" />
  </SpinnerWrapper>
);

export default Spinner;
