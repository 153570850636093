import React from 'react';
import { Style } from '@react-pdf/types';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  Patient,
  AlteredFrenulum,
  FeedingInterval,
  FeedingFatigue,
  SuckAndSleep,
  SlipsOffNipple,
  ChewsNipple,
} from 'modules/Main/Patients/types';
import CompleteReportHeader from '../Header';
import { clinicalHistoryExamScoreMap } from 'modules/Main/Patients/utils/score';

const styles = StyleSheet.create({
  page: {
    padding: '12 20 12 20',
    fontSize: 10,
  },
  section: { border: '1px solid black', marginBottom: 12, padding: 12 },
  title: { fontFamily: 'Helvetica-Bold', marginBottom: 12 },
  detailItem: {
    marginBottom: 4,
  },
  footer: {
    fontSize: 10,
    margin: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

type Props = {
  checked?: boolean;
  style?: Style | Style[];
  children: React.ReactNode;
};

const Checkbox: React.FC<Props> = ({ children, checked = false, style }) => (
  <Text style={{ marginBottom: 4, ...style }}>
    ( {checked ? 'X' : '  '} ) {children}
  </Text>
);

const isChecked = (optionValue: string, patientValue?: string): boolean => optionValue === patientValue;

const ClinicalHistoryPage: React.FC<{ patient: Patient }> = ({ patient }) => {
  const [t] = useTranslation();

  const ageInDays = moment().diff(moment(patient.birth), 'days');

  return (
    <Page size="A4" style={styles.page}>
      <CompleteReportHeader examTitle={t('reports.clinicalHistoryExam.title')} />
      <View style={{ ...styles.section }}>
        <Text style={{ ...styles.detailItem }}>{t('reports.clinicalHistoryExam.name', { name: patient.name })}</Text>
        <Text style={{ ...styles.detailItem }}>
          {t('reports.clinicalHistoryExam.examinationDate', {
            date: moment(patient.anatomyExam?.lastModified).format(t('dateFormat')),
          })}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...styles.detailItem,
          }}
        >
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.birth', {
              date: moment(patient.birth).format(t('dateFormat')),
            })}
          </Text>
          <Text style={{ flexGrow: 1 }}>{t('reports.clinicalHistoryExam.age', { age: ageInDays })}</Text>
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.sex', {
              sex: t(`reports.clinicalHistoryExam.sexValues.${patient.gender}`),
            })}
          </Text>
        </View>
        <Text style={{ ...styles.detailItem }}>
          {t('reports.clinicalHistoryExam.parentName', {
            name: patient.parentName,
          })}
        </Text>
        <Text style={{ ...styles.detailItem }}>
          {patient.address
            ? t('reports.clinicalHistoryExam.address', {
                street: patient.address?.street,
                number: patient.address?.number,
              })
            : t('reports.clinicalHistoryExam.address-empty')}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...styles.detailItem,
          }}
        >
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.city', {
              city: patient.address?.city,
            })}
          </Text>
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.state', {
              state: patient.address?.state,
            })}
          </Text>
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.zip', {
              zip: patient.address?.zip,
            })}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            ...styles.detailItem,
          }}
        >
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.phone', {
              phone: patient.contact.primaryPhone,
            })}
          </Text>
          <Text style={{ flexGrow: 1 }}>
            {t('reports.clinicalHistoryExam.alternativePhone', {
              phone: patient.contact.alternativePhone,
            })}
          </Text>
        </View>
        <Text style={{ ...styles.detailItem }}>
          {t('reports.clinicalHistoryExam.email', {
            email: patient.contact.email,
          })}
        </Text>
      </View>
      <View style={{ ...styles.section }}>
        <Text style={{ ...styles.detailItem, ...styles.title }}>
          {t('reports.clinicalHistoryExam.familyHistoryTitle')}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 8,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              style={{ marginRight: 24 }}
              checked={isChecked(AlteredFrenulum.NO, patient.clinicalHistoryExam?.alteredFrenulum)}
            >
              {t('reports.clinicalHistoryExam.alteredFrenulum.no')}
              {` (${clinicalHistoryExamScoreMap.alteredFrenulum.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(AlteredFrenulum.YES, patient.clinicalHistoryExam?.alteredFrenulum)}>
              {t('reports.clinicalHistoryExam.alteredFrenulum.yes')}
              {` (${clinicalHistoryExamScoreMap.alteredFrenulum.YES})`}
            </Checkbox>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1 }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>{t('reports.clinicalHistoryExam.who')}</Text>
              {patient.clinicalHistoryExam?.familyHistory?.alterations?.map(alteration => {
                return <Text key={alteration.who}>{alteration.who}</Text>;
              })}
            </View>
            <View style={{ flexGrow: 1 }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>{t('reports.clinicalHistoryExam.what')}</Text>
              {patient.clinicalHistoryExam?.familyHistory?.alterations?.map(alteration => {
                return <Text key={alteration.what}>{alteration.what}</Text>;
              })}
            </View>
          </View>
        </View>
        <Text style={{ ...styles.detailItem, ...styles.title }}>
          {t('reports.clinicalHistoryExam.otherIssues.title')}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 8,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              checked={patient.clinicalHistoryExam && Boolean(!patient.clinicalHistoryExam?.healthIssues?.length)}
              style={{ marginRight: 24 }}
            >
              {t('reports.clinicalHistoryExam.otherIssues.no')}
            </Checkbox>
            <Checkbox checked={Boolean(patient.clinicalHistoryExam?.healthIssues?.length)}>
              {t('reports.clinicalHistoryExam.otherIssues.yes')}
            </Checkbox>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>{t('reports.clinicalHistoryExam.otherIssues.what')}</Text>
              {patient.clinicalHistoryExam?.healthIssues?.map(issue => {
                return <Text key={issue}>{issue}</Text>;
              })}
            </View>
          </View>
        </View>
      </View>

      <View style={{ ...styles.section }}>
        <Text style={{ ...styles.detailItem, ...styles.title }}>{t('reports.clinicalHistoryExam.breastfeeding')}</Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 4,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                {t('reports.clinicalHistoryExam.feedingInterval.title')}
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              checked={isChecked(FeedingInterval.TWO_MORE, patient.clinicalHistoryExam?.feedingInterval)}
              style={{ marginRight: 24 }}
            >
              {t('reports.clinicalHistoryExam.feedingInterval.twoMore')}
              {` (${clinicalHistoryExamScoreMap.feedingInterval.TWO_MORE})`}
            </Checkbox>
            <Checkbox checked={isChecked(FeedingInterval.ONE_LESS, patient.clinicalHistoryExam?.feedingInterval)}>
              {t('reports.clinicalHistoryExam.feedingInterval.oneLess')}
              {` (${clinicalHistoryExamScoreMap.feedingInterval.ONE_LESS})`}
            </Checkbox>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 4,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                {t('reports.clinicalHistoryExam.feedingFatigue.title')}
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              checked={isChecked(FeedingFatigue.NO, patient.clinicalHistoryExam?.feedingFatigue)}
              style={{ marginRight: 24 }}
            >
              {t('reports.clinicalHistoryExam.feedingFatigue.no')}
              {` (${clinicalHistoryExamScoreMap.feedingFatigue.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(FeedingFatigue.YES, patient.clinicalHistoryExam?.feedingFatigue)}>
              {t('reports.clinicalHistoryExam.feedingFatigue.yes')}
              {` (${clinicalHistoryExamScoreMap.feedingFatigue.YES})`}
            </Checkbox>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 4,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                {t('reports.clinicalHistoryExam.suckAndSleep.title')}
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              checked={isChecked(SuckAndSleep.NO, patient.clinicalHistoryExam?.suckAndSleep)}
              style={{ marginRight: 24 }}
            >
              {t('reports.clinicalHistoryExam.suckAndSleep.no')}
              {` (${clinicalHistoryExamScoreMap.suckAndSleep.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(SuckAndSleep.YES, patient.clinicalHistoryExam?.suckAndSleep)}>
              {t('reports.clinicalHistoryExam.suckAndSleep.yes')}
              {` (${clinicalHistoryExamScoreMap.suckAndSleep.YES})`}
            </Checkbox>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 4,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>
                {t('reports.clinicalHistoryExam.slipsOffNipple.title')}
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              checked={isChecked(SlipsOffNipple.NO, patient.clinicalHistoryExam?.slipsOffNipple)}
              style={{ marginRight: 24 }}
            >
              {t('reports.clinicalHistoryExam.slipsOffNipple.no')}
              {` (${clinicalHistoryExamScoreMap.slipsOffNipple.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(SlipsOffNipple.YES, patient.clinicalHistoryExam?.slipsOffNipple)}>
              {t('reports.clinicalHistoryExam.slipsOffNipple.yes')}
              {` (${clinicalHistoryExamScoreMap.slipsOffNipple.YES})`}
            </Checkbox>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginBottom: 24,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <View style={{ flexGrow: 1, justifyContent: 'flex-start' }}>
              <Text style={{ fontFamily: 'Helvetica-Bold' }}>{t('reports.clinicalHistoryExam.chewsNipple.title')}</Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
            <Checkbox
              checked={isChecked(ChewsNipple.NO, patient.clinicalHistoryExam?.chewsNipple)}
              style={{ marginRight: 24 }}
            >
              {t('reports.clinicalHistoryExam.chewsNipple.no')}
              {` (${clinicalHistoryExamScoreMap.chewsNipple.NO})`}
            </Checkbox>
            <Checkbox checked={isChecked(ChewsNipple.YES, patient.clinicalHistoryExam?.chewsNipple)}>
              {t('reports.clinicalHistoryExam.chewsNipple.yes')}
              {` (${clinicalHistoryExamScoreMap.chewsNipple.YES})`}
            </Checkbox>
          </View>
        </View>
        <Text>{t('reports.clinicalHistoryExam.results')}</Text>
      </View>

      <View style={styles.footer} fixed>
        <Text>{t('reports.footer.copyright')}</Text>
      </View>
    </Page>
  );
};

export default ClinicalHistoryPage;
