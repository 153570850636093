import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

type OptionProps = {
  children: React.ReactNode;
};

export const Options: React.FC<OptionProps> = ({ children }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'center',
    }}
  >
    {children}
  </View>
);

type ImageOptionProps = { src: string; count: number; text: string; checked?: boolean; showCount?: boolean };

export const Option: React.FC<ImageOptionProps> = ({ checked = false, count, src, text, showCount = true }) => (
  <View
    style={{
      width: '25%',
      paddingRight: 2,
      paddingLeft: 2,
      marginBottom: 12,
    }}
  >
    <View style={{ display: 'flex', alignItems: 'center' }}>
      <Image
        src={src}
        style={{
          width: 72,
          height: 48,
          marginBottom: 4,
        }}
      />
    </View>
    <Text style={{ fontSize: 8, textAlign: 'center' }}>
      ( {checked ? 'X' : ' '} ) {text} {showCount ? `(${count})` : ''}
    </Text>
  </View>
);
