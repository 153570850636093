import { useMutation, useQuery } from '@apollo/client';
import { pathOr, pipe } from 'ramda';
import { useCallback } from 'react';
import { clearApolloObject } from 'services/Apollo';
import { AnatomyExam, ClinicalHistoryExam, SuckingExam } from '../../types';
import {
  ADD_ANATOMY_EXAM,
  ADD_CLINICAL_HISTORY_EXAM,
  ADD_SUCKING_EXAM,
  GET_ANATOMY_EXAM,
  GET_CLINICAL_HISTORY_EXAM,
  GET_SUCKING_EXAM,
  REMOVE_ANATOMY_EXAM,
  REMOVE_CLINICAL_HISTORY_EXAM,
  REMOVE_SUCKING_EXAM,
} from './graphql';

const transform = (path: string[], initialData) => pipe(pathOr(initialData, path), clearApolloObject);

export const useAnatomyExam = (patientId?: string) => {
  const [_update] = useMutation(ADD_ANATOMY_EXAM, { refetchQueries: ['PatientsGetPatientList'] });
  const update = useCallback(
    (patientId: string, exam: AnatomyExam) => _update({ variables: { patientId, exam } }),
    [_update]
  );

  const [_remove] = useMutation(REMOVE_ANATOMY_EXAM, {
    refetchQueries: ['PatientsGetPatientList'],
  });
  const remove = useCallback((patientId: string) => _remove({ variables: { patientId } }), [_remove]);

  const { data, loading, error } = useQuery(GET_ANATOMY_EXAM, {
    skip: !patientId,
    variables: { id: patientId },
    fetchPolicy: 'network-only',
  });
  return { data: transform(['patient', 'anatomyExam'], {})(data) as AnatomyExam, loading, error, update, remove };
};

export const useSuckingExam = (patientId?: string) => {
  const [_update] = useMutation(ADD_SUCKING_EXAM, { refetchQueries: ['PatientsGetPatientList'] });
  const update = useCallback(
    (patientId: string, exam: SuckingExam) => _update({ variables: { patientId, exam } }),
    [_update]
  );

  const [_remove] = useMutation(REMOVE_SUCKING_EXAM, {
    refetchQueries: ['PatientsGetPatientList'],
  });
  const remove = useCallback((patientId: string) => _remove({ variables: { patientId } }), [_remove]);

  const { data, loading, error } = useQuery(GET_SUCKING_EXAM, {
    skip: !patientId,
    variables: { id: patientId },
    fetchPolicy: 'network-only',
  });

  return { data: transform(['patient', 'suckingExam'], {})(data) as SuckingExam, loading, error, update, remove };
};

export const useClinicalHistoryExam = (patientId?: string) => {
  const [_update] = useMutation(ADD_CLINICAL_HISTORY_EXAM, { refetchQueries: ['PatientsGetPatientList'] });
  const update = useCallback(
    (patientId: string, exam: SuckingExam) => _update({ variables: { patientId, exam } }),
    [_update]
  );
  const [_remove] = useMutation(REMOVE_CLINICAL_HISTORY_EXAM, {
    refetchQueries: ['PatientsGetPatientList'],
  });
  const remove = useCallback((patientId: string) => _remove({ variables: { patientId } }), [_remove]);

  const { data, loading, error } = useQuery(GET_CLINICAL_HISTORY_EXAM, {
    skip: !patientId,
    variables: { id: patientId },
    fetchPolicy: 'network-only',
  });

  return {
    data: transform(['patient', 'clinicalHistoryExam'], {})(data) as ClinicalHistoryExam,
    loading,
    error,
    update,
    remove,
  };
};
