import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { isNil, when, is, pipe, reject, map, omit, identity, ifElse } from 'ramda';
import { getNewToken } from 'services/Authentication';

export const clearApolloObject = when(
  is(Object),
  pipe(
    ifElse(is(Array), identity, omit(['__typename'])),
    // @ts-ignore
    reject(isNil),
    map(a => clearApolloObject(a))
  )
);

const authenticatedFetch = async (uri, options) => {
  const token = await getNewToken();
  options.headers.Authorization = token ? `Bearer ${token}` : '';
  return fetch(uri, options);
};

const link = new HttpLink({ uri: `${process.env.REACT_APP_API}/graphql`, fetch: authenticatedFetch });

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default client;
