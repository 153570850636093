import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuProps, Modal } from 'antd';
import { LogoutOutlined, QuestionCircleOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { signOut } from 'services/Authentication';
import ROUTES from 'routes';

const items = (t: (k: string) => string) => [
  {
    key: ROUTES.PATIENTS,
    icon: <TeamOutlined />,
    label: t('menu.patients'),
  },
  {
    key: ROUTES.ACCOUNT,
    icon: <UserOutlined />,
    label: t('menu.account'),
  },
  {
    key: 'help',
    icon: <QuestionCircleOutlined />,
    label: t('menu.help.label'),
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: t('menu.logout'),
  },
];

const SiderMenu = () => {
  const [t] = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState<string>(pathname);
  const navigate = useNavigate();

  const signOutCallback = () => {
    Modal.confirm({
      title: t('menu.confirmation.title'),
      okText: t('menu.confirmation.yes'),
      cancelText: t('menu.confirmation.no'),
      centered: true,
      onOk() {
        signOut();
      },
    });
  };

  const handleMenuClick: MenuProps['onClick'] = item => {
    setActive(item.key);

    if (item.key === 'help') {
      return window.open(t('menu.help.link'), '_newtab');
    }

    if (item.key === 'logout') {
      return signOutCallback();
    }

    return navigate(item.key);
  };

  return <Menu theme="light" mode="inline" items={items(t)} onClick={handleMenuClick} selectedKeys={[active]} />;
};

export default SiderMenu;
