import React from 'react';
import { useTranslation } from 'react-i18next';
import ROUTES from 'routes';

const PrivacyAndTerms: React.FC = () => {
  const [t] = useTranslation();
  return (
    <span>
      <span>{t('signup.form.legal.description')}</span>
      <a href={ROUTES.TERMS_AND_CONDITIONS}> {t('signup.form.legal.terms')}</a>
      <span>{t('signup.form.legal.and')}</span>
      <a href={ROUTES.PRIVACY_POLICIES}> {t('signup.form.legal.privacy')}</a>
    </span>
  );
};

export default PrivacyAndTerms;
