import React from 'react';
import { Form, Radio, RadioChangeEvent } from 'antd';

type Props = {
  name: string;
  options: { value: string; name: React.ReactElement }[];
  onChange: (e: RadioChangeEvent) => void;
  value?: string;
};

const ButtonGroup: React.FC<Props> = ({ name, options, onChange, value }) => (
  <Form.Item>
    <Radio.Group name={name} onChange={onChange} value={value}>
      {options.map(({ value, name }) => (
        <Radio.Button key={value} style={{ padding: 10, height: 'auto', width: 210 }} value={value}>
          {name}
        </Radio.Button>
      ))}
    </Radio.Group>
  </Form.Item>
);

export default ButtonGroup;
