import { gql } from '@apollo/client';

export const GET_PATIENTS = gql`
  query PatientsGetPatientList(
    $offset: ID
    $term: String
    $filterBy: FilterOptions
    $sortBy: SortOptions
    $sortOrder: SortOrder
  ) {
    patients(offset: $offset, term: $term, filterBy: $filterBy, sortBy: $sortBy, sortOrder: $sortOrder) {
      uid
      parentName
      name
      examinationResults {
        name
        score
      }
      anatomyExam {
        score
      }
      clinicalHistoryExam {
        score
      }
      suckingExam {
        score
      }
    }
  }
`;

export const GET_PATIENT_INFO = gql`
  query GetPatient($id: ID!) {
    patient(id: $id) {
      parentName
      parentNationalId
      name
      birth
      gender
      contact {
        primaryPhone
        alternativePhone
        email
      }
      address {
        street
        city
        number
        state
        zip
      }
    }
  }
`;

export const GET_PATIENT = gql`
  query GetActionDrawerPatient($id: ID!) {
    patient(id: $id) {
      parentName
      parentNationalId
      name
      birth
      gender
      contact {
        primaryPhone
        alternativePhone
        email
      }
      address {
        street
        city
        number
        state
        zip
      }
      examinationResults {
        name
        score
      }
      anatomyExam {
        score
        lipPosture
        tonguePosture
        apexShape
        lingualFrenulum
        frenulumThickness
        frenulumTongueAttach
        frenulumFloorAttach
        lastModified
      }
      clinicalHistoryExam {
        score
        alteredFrenulum
        feedingInterval
        feedingFatigue
        suckAndSleep
        slipsOffNipple
        chewsNipple
        familyHistory {
          alterations {
            who
            what
          }
        }
        healthIssues
      }
      suckingExam {
        score
        tongueMovement
        suckingRhythm
        coordination
        nipleChewing
        clicking
      }
    }
  }
`;

export const ADD_PATIENT = gql`
  mutation AddPatient($patient: PatientInput!) {
    addPatient(patient: $patient) {
      uid
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient($id: ID!, $patient: PatientInput!) {
    updatePatient(id: $id, patient: $patient) {
      parentName
    }
  }
`;

export const REMOVE_PATIENT = gql`
  mutation ActionDrawerRemovePatient($id: ID!) {
    removePatient(id: $id) {
      uid
    }
  }
`;
