import React from 'react';
import { Document } from '@react-pdf/renderer';

import { Patient } from '../../types';
import AnatomyExamPage from './AnatomyExam';
import ClinicalHistoryPage from './ClinicalHistory';
import SuckingExamPage from './SuckingExamPage';

const CompleteReport: React.FC<{ patient: Patient }> = ({ patient }) => (
  <Document>
    <ClinicalHistoryPage patient={patient} />
    <AnatomyExamPage patient={patient} />
    <SuckingExamPage patient={patient} />
  </Document>
);

export default CompleteReport;
