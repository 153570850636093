import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form, Button, message } from 'antd';
import { propEq, pipe, omit, when } from 'ramda';
import { object, string } from 'yup';

import { ApiErrorLogger } from 'services/ApiErrorLogger';
import DrawerSpinner from 'components/DrawerSpinner';
import ButtonGroup from 'components/Form/ButtonGroup';

import ButtonBadge from '../../components/ButtonBadge';
import { ANATOMO_FUNCTIONAL, evaluateExamScore } from '../../utils/score';
import {
  lipPosture,
  tonguePosture,
  apexShape,
  lingualFrenulum,
  frenulumThickness,
  frenulumTongueAttach,
  frenulumFloorAttach,
} from './Options/options';
import { DrawerFooter, DrawerContainer } from '../../components/FixedFooterDrawer';
import { useAnatomyExam } from '../../services/exams';
import { DrawerProps } from '../../components/PatientDrawer';
import { AnatomyExam } from '../../types';

export const getAnatomyExamSchema = (t: (k: string) => string) =>
  object().shape({
    lipPosture: string().required(t('validation.required')),
    tonguePosture: string().required(t('validation.required')),
    apexShape: string().required(t('validation.required')),
    lingualFrenulum: string().required(t('validation.required')),
    frenulumThickness: string().when('lingualFrenulum', {
      is: (value: string) => ['VISIBLE', 'VISIBLE_MANEUVER'].includes(value),
      then: string().required(t('validation.required')),
    }),
    frenulumTongueAttach: string().when('lingualFrenulum', {
      is: (value: string) => ['VISIBLE', 'VISIBLE_MANEUVER'].includes(value),
      then: string().required(t('validation.required')),
    }),
    frenulumFloorAttach: string().when('lingualFrenulum', {
      is: (value: string) => ['VISIBLE', 'VISIBLE_MANEUVER'].includes(value),
      then: string().required(t('validation.required')),
    }),
  });

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MarginRight = styled.div`
  margin-right: 16px;
`;

const FormContainer = styled.div`
  .ant-radio-group .ant-radio-button-wrapper {
    margin: 16px;
  }

  .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    border: 2px solid #1890ff;
    border-left: 1px solid #1890ff;
  }

  .ant-radio-group .ant-radio-button-wrapper-checked:first-child {
    border-left: 2px solid #1890ff;
  }
`;

const AnatomyExamDrawer: React.FC<DrawerProps> = ({ close, showConfirm, confirmClose }) => {
  const [t] = useTranslation();
  const { patientId } = useParams();
  const { data: initialValues, loading, error, update: addAnatomyExam } = useAnatomyExam(patientId);

  const validationSchema = useMemo(() => getAnatomyExamSchema(t), [t]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (exam: AnatomyExam) => {
      if (!patientId) {
        return;
      }

      const clearUnusedFields = when(
        propEq('lingualFrenulum', 'NOT_VISIBLE'),
        pipe(omit(['frenulumThickness', 'frenulumFloorAttach', 'frenulumTongueAttach']))
      );

      try {
        // @ts-ignore
        await addAnatomyExam(patientId, clearUnusedFields(exam));
        message.success(t('success.exam-add'));
        close();
      } catch (err) {
        message.error(t('error.exam-add'));
        ApiErrorLogger(err);
      }
    },
  });

  const isFrenulumDetailsVisible = useMemo(
    () => formik.values.lingualFrenulum === 'VISIBLE' || formik.values.lingualFrenulum === 'VISIBLE_MANEUVER',
    [formik.values.lingualFrenulum]
  );

  if (error) {
    message.error(t('error.get-patient'));
  }

  if (loading) {
    return <DrawerSpinner />;
  }

  showConfirm(formik.dirty);

  return (
    <DrawerContainer>
      <FormContainer>
        <Form onFinish={formik.handleSubmit} layout="vertical">
          <h2>{t('exam.anatomy.title')}</h2>

          <h3>{t('exam.anatomy.lips.title')}</h3>
          <ButtonGroup
            name="lipPosture"
            options={lipPosture}
            onChange={formik.handleChange}
            value={formik.values.lipPosture}
          />

          <h3>{t('exam.anatomy.tongue.title')}</h3>
          <ButtonGroup
            name="tonguePosture"
            options={tonguePosture}
            onChange={formik.handleChange}
            value={formik.values.tonguePosture}
          />

          <h3>{t('exam.anatomy.apex.title')}</h3>
          <ButtonGroup
            name="apexShape"
            options={apexShape}
            onChange={formik.handleChange}
            value={formik.values.apexShape}
          />

          <h3>{t('exam.anatomy.frenulum.title')}</h3>
          <ButtonGroup
            name="lingualFrenulum"
            options={lingualFrenulum}
            onChange={formik.handleChange}
            value={formik.values.lingualFrenulum}
          />

          <span>{t('exam.anatomy.frenulum.titleObservation')}</span>

          {isFrenulumDetailsVisible && (
            <>
              <h3>{t('exam.anatomy.thickness.title')}</h3>
              <ButtonGroup
                name="frenulumThickness"
                options={frenulumThickness}
                onChange={formik.handleChange}
                value={formik.values.frenulumThickness}
              />

              <h3>{t('exam.anatomy.tongueAttach.title')}</h3>
              <ButtonGroup
                name="frenulumTongueAttach"
                options={frenulumTongueAttach}
                onChange={formik.handleChange}
                value={formik.values.frenulumTongueAttach}
              />

              <h3>{t('exam.anatomy.floorAttach.title')}</h3>
              <ButtonGroup
                name="frenulumFloorAttach"
                options={frenulumFloorAttach}
                onChange={formik.handleChange}
                value={formik.values.frenulumFloorAttach}
              />
            </>
          )}

          <DrawerFooter>
            <ButtonsContainer>
              <MarginRight>
                <Button type="default" onClick={confirmClose}>
                  {t('exam.anatomy.cancel')}
                </Button>
              </MarginRight>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!formik.isValid || !formik.dirty}
                loading={formik.isSubmitting}
              >
                {t('exam.anatomy.save')}
                <ButtonBadge showZero count={evaluateExamScore(ANATOMO_FUNCTIONAL, formik.values)} />
              </Button>
            </ButtonsContainer>
          </DrawerFooter>
        </Form>
      </FormContainer>
    </DrawerContainer>
  );
};

export default AnatomyExamDrawer;
