import React from 'react';

import { useParams, generatePath, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { Typography, Divider, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';

import routes from 'routes';
import { ApiErrorLogger } from 'services/ApiErrorLogger';
import DrawerSpinner from 'components/DrawerSpinner';
import { DrawerFooter, DrawerContainer } from '../../components/FixedFooterDrawer';
import { EXAM } from '../../types';
import PatientSummary from './PatientSummary';
import NotExamined from './NotExamined';
import PatientExams from './PatientExams';
import PatientReports from './PatientReports';
import removePatientDialog from './RemovePatient';
import removeExam from '../../utils/removeExam';
import { usePatientAndExams, usePatientManager } from '../../services/patients';
import { useAnatomyExam, useClinicalHistoryExam, useSuckingExam } from '../../services/exams';
import { DrawerProps } from '../../components/PatientDrawer';

const Actions: React.FC<DrawerProps> = ({ close }) => {
  const [t] = useTranslation();
  const { patientId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: patient, loading, error } = usePatientAndExams(patientId);
  const { remove: removePatient } = usePatientManager();
  const { remove: removeAnatomyExam } = useAnatomyExam();
  const { remove: removeSuckingExam } = useSuckingExam();
  const { remove: removeClinicalHitoryExam } = useClinicalHistoryExam();

  const handleRemoveExamClick = (exam: EXAM) => (): void => {
    const removeMap = {
      [EXAM.ANATOMY_EXAM]: removeAnatomyExam,
      [EXAM.SUCKING_EXAM]: removeSuckingExam,
      [EXAM.CLINICAL_HISTORY_EXAM]: removeClinicalHitoryExam,
    };

    const onOk = async (): Promise<void> => {
      if (!patientId) {
        return;
      }

      try {
        await removeMap[exam](patientId);
        close();
        message.success(t('success.exam-remove'));
      } catch (err) {
        message.error(t('error.exam-remove'));
        ApiErrorLogger(err);
      }
    };

    removeExam(onOk, exam, t);
  };

  const handlePatientDetailsClick = (): void => {
    navigate(generatePath(routes.PATIENTS_EDIT, { patientId }));
  };

  const handleDeletePatientClick = (): void => {
    if (!patientId) {
      return;
    }

    removePatientDialog(async () => {
      try {
        await removePatient(patientId);
        close();
        message.success(t('success.patient-remove'));
      } catch (err) {
        message.error(t('error.patient-remove'));
        ApiErrorLogger(err);
      }
    }, t);
  };

  if (!patientId || error) {
    return <Navigate to={routes.PATIENTS} state={{ from: location }} />;
  }

  if (loading) {
    return <DrawerSpinner />;
  }

  return (
    <DrawerContainer>
      <Typography.Title level={2}>{t('actions.title')}</Typography.Title>
      <PatientSummary
        patient={patient}
        onDetailsClick={handlePatientDetailsClick}
        onRemoveClick={handleDeletePatientClick}
      />

      <Divider />

      {patient.examinationResults ? (
        <>
          <PatientExams patient={patient} patientId={patientId} onRemoveExam={handleRemoveExamClick} />
          <Divider />
          <PatientReports patient={patient} patientId={patientId} />
        </>
      ) : (
        <NotExamined patientId={patientId} />
      )}

      <DrawerFooter>
        <Button type="default" onClick={close}>
          {t('actions.cancel')}
        </Button>
      </DrawerFooter>
    </DrawerContainer>
  );
};

export default Actions;
