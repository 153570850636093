import { useCallback } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { UPDATE_SUBSCRIPTION } from '../graphql';
import { BILLING_INFO } from '../services/graphql';
import { BillingInfoData } from '../services/types';

export const useBilling = () => {
  const { data, loading, error } = useQuery<BillingInfoData>(BILLING_INFO);
  return { data: data ? data.billingInfo : null, loading, error };
};

export const useSubscription = () => {
  const [updateSubscription, { loading }] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: ['BillingInfo'],
  });

  const cancelSubscription = useCallback(() => {
    return updateSubscription({ variables: { cancel: true } });
  }, [updateSubscription]);

  const activateSubscription = useCallback(() => {
    return updateSubscription({ variables: { cancel: false } });
  }, [updateSubscription]);

  return {
    activateSubscription,
    cancelSubscription,
    loading,
  };
};
