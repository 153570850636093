import { gql } from '@apollo/client';

export const CREATE_SESSION = gql`
  mutation CreateNewPaymentSession($couponId: ID, $locale: String) {
    createSession(couponId: $couponId, locale: $locale) {
      id
      customer
    }
  }
`;

export const RETRIEVE_PLAN = gql`
  query RetrievePlan($locale: String) {
    retrievePlan(locale: $locale) {
      amount
      interval_count
      currency
    }
  }
`;
