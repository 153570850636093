import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import routes from 'routes';
import { useMedia } from 'react-use';
import { List, Button, message } from 'antd';

import { ApiErrorLogger } from 'services/ApiErrorLogger';
import { ExaminationBadge } from '../components/ExaminationBadge';
import { Title, Description } from '../components/PatientItem';
import { Patient } from '../types';
import { ContextButton } from '../components/ContextButton';
import { ListHeader, SearchValues } from '../components/ListHeader';
import { usePatients } from '../services/patients';

const LoadButtonContainer = styled.div`
  margin-top: 25px;
  text-align: center;
`;

const ListItem = styled(List.Item)`
  cursor: pointer;
`;

const PatientsList: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const isExtraSmall = useMedia('(max-width: 480px)');

  const { data: patients, loading, fetchMore, refetch: refetchPatients, hasMorePatients } = usePatients();

  const handleSearch = async (filterValues: SearchValues): Promise<void> => {
    try {
      await refetchPatients(filterValues.filterBy, filterValues.term);
    } catch (err) {
      message.error(t('error.search'));
      ApiErrorLogger(err);
    }
  };

  const actionButtons = (patient: Patient) => [
    isExtraSmall ? null : <ContextButton patient={patient} navigate={navigate} />,
  ];

  const notEmpty = array => array.filter(element => !!element);

  const handlePatientClick = (patient: Patient) => (): void => {
    navigate(generatePath(routes.PATIENT_DRAWER, { patientId: patient.uid }));
  };

  return (
    <>
      <List
        loading={loading && !patients.length}
        itemLayout="horizontal"
        locale={{ emptyText: t('patient.empty-list') }}
        header={<ListHeader onSubmit={handleSearch} />}
        loadMore={
          !loading && (
            <LoadButtonContainer>
              <Button disabled={!hasMorePatients} onClick={fetchMore}>
                {t('patient.load')}
              </Button>
            </LoadButtonContainer>
          )
        }
        dataSource={patients}
        renderItem={(patientItem: Patient) => (
          <ListItem actions={notEmpty(actionButtons(patientItem))} onClick={handlePatientClick(patientItem)}>
            <List.Item.Meta
              avatar={<ExaminationBadge status={patientItem.examinationResults} />}
              title={<Title patient={patientItem} />}
              description={<Description patient={patientItem} />}
            />
          </ListItem>
        )}
      />
      <Outlet />
    </>
  );
};

export default PatientsList;
