import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Radio, Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

enum Currency {
  en = 'en',
  'pt-BR' = 'pt-BR',
}

interface CurrencySelectorProps {
  locale: string;
  setLocale: (locale: string) => void;
}

const CurrencySelector: React.FC<CurrencySelectorProps> = ({ locale, setLocale }) => {
  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);
  const [currency, setCurrency] = useState(Currency[locale] || Currency.en);

  const handleChange = useCallback((event: RadioChangeEvent): void => {
    setCurrency(event.target.value);
  }, []);

  const closeModal = useCallback((): void => {
    setCurrency(Currency[locale] || Currency.en);
    setVisible(false);
  }, [locale]);

  const openModal = useCallback((): void => setVisible(true), []);
  const onOk = useCallback((): void => {
    setLocale(currency);
    setVisible(false);
  }, [currency, setLocale]);

  return (
    <Container>
      <Button type="link" onClick={openModal}>
        {t('plan.currencySelector.buttonLabel')}
      </Button>
      <Modal title={t('plan.currencySelector.title')} visible={visible} onOk={onOk} onCancel={closeModal}>
        <Radio.Group onChange={handleChange} value={currency}>
          <Radio value={Currency.en}>{t('plan.currencySelector.en')}</Radio>
          <Radio value={Currency['pt-BR']}>{t('plan.currencySelector.pt-BR')}</Radio>
        </Radio.Group>
      </Modal>
    </Container>
  );
};

export default CurrencySelector;
