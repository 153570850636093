import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';

import { Patient, ResultType } from 'modules/Main/Patients/types';
import { getNextApplicableExam, getExaminationBoxType, EXAM_META } from '../utils';

const MarginTopBlock = styled.div`
  margin-top: 16px;
`;

const MarginLeftBlock = styled.span`
  margin-left: 8px;
`;

interface ExaminationBoxProps {
  patient: Patient;
  patientId: string;
}

const ExaminationBox: React.FC<ExaminationBoxProps> = ({ patient, patientId }) => {
  const [t] = useTranslation();
  const nextExam = getNextApplicableExam(patient);
  const alertType = getExaminationBoxType(patient.examinationResults?.name as ResultType);

  return (
    <Alert
      type={alertType}
      message={`${patient.examinationResults?.score} - ${t(`patient.condition.${patient.examinationResults?.name}`)}`}
      description={
        <>
          <div>{t('actions.examinationBox.results-description')}</div>
          {nextExam && (
            <>
              <MarginTopBlock>
                <>
                  <strong>{t('actions.examinationBox.suggested-action')}</strong>
                  <MarginLeftBlock>
                    <Link to={generatePath(EXAM_META[nextExam].link, { patientId })}>
                      <Button type="default" size="small">
                        {t(EXAM_META[nextExam].i18nKey)}
                        <RightOutlined />
                      </Button>
                    </Link>
                  </MarginLeftBlock>
                </>
              </MarginTopBlock>
            </>
          )}
        </>
      }
    />
  );
};

export default ExaminationBox;
