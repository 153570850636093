import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Row, Col, message, Skeleton } from 'antd';
import styled from 'styled-components';
import { propOr } from 'ramda';

import { sendPasswordResetEmail, useSession } from 'services/Authentication';
import { UserOutlined } from '@ant-design/icons';

const FirstLine = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 0;
`;

const SecondLine = styled.div`
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.h3`
  color: gray;
  text-transform: uppercase;
`;

const UpdatePasswordLinkButton = styled(Button)`
  &.ant-btn-link {
    padding: 0;
  }
`;

const UserInfo = styled.div`
  margin-left: 12px;
`;

export const Profile: React.FC = () => {
  const [t] = useTranslation();
  const { auth } = useSession();
  const [loadingResetEmail, setLoadingResetEmail] = useState(false);

  // Safely destructuring and placeholders for <Skeleton />
  const photoURL: string | undefined = propOr(undefined, 'photoURL')(auth);
  const displayName: string | undefined = propOr(undefined, 'displayName')(auth);
  const email: string = propOr('', 'email')(auth);
  const loading = auth ? false : true;

  const resetEmail = async (): Promise<void> => {
    setLoadingResetEmail(true);
    await sendPasswordResetEmail(email);
    setLoadingResetEmail(false);
    message.success(t('account.resetEmailSuccess'));
  };

  return (
    <Row>
      <Col xs={24} md={8}>
        <Title>{t('account.profile')}</Title>
      </Col>

      <Col xs={24} md={16}>
        <Skeleton loading={loading} avatar active>
          <ProfileDetails>
            {photoURL ? (
              <Avatar size="large" src={<img src={photoURL} referrerPolicy="no-referrer" />} />
            ) : (
              <Avatar size="large" icon={<UserOutlined />} />
            )}
            <UserInfo>
              <FirstLine>{displayName ? displayName : email}</FirstLine>
              {displayName ? <SecondLine>{email}</SecondLine> : null}
            </UserInfo>
          </ProfileDetails>
          <UpdatePasswordLinkButton type="link" loading={loadingResetEmail} onClick={resetEmail}>
            {t('account.resetPassword')}
          </UpdatePasswordLinkButton>
        </Skeleton>
      </Col>
    </Row>
  );
};
