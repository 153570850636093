import 'antd/dist/reset.css';

import './i18n';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Spinner from 'components/Spinner';

import { ApolloProvider } from '@apollo/client';
import { init as sentryInit } from '@sentry/browser';
import App from './modules/App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from './services/Apollo';

if (process.env.REACT_APP_SENTRY_PUBLIC_DSN) {
  sentryInit({ dsn: process.env.REACT_APP_SENTRY_PUBLIC_DSN });
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <Suspense fallback={<Spinner />}>
      <ApolloProvider client={ApolloClient}>
        <App />
      </ApolloProvider>
    </Suspense>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
