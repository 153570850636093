import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { captureException } from '@sentry/browser';
import { Skeleton, Modal, Button, Row, Col, message } from 'antd';
import { propOr } from 'ramda';

import { ApiErrorLogger } from 'services/ApiErrorLogger';
import { formatPrice } from 'utils/money';
import { formatUnixDate } from 'utils/date';
import { InlineErrorMessage } from 'components/InlineErrorMessage';
import { proceedToCheckout, useStripeSession } from 'services/Stripe';

import { isPaymentMethodInvalidError } from '../utils';
import { CREATE_UPDATE_SESSION } from '../graphql';
import { useBilling, useSubscription } from '../services';

const Title = styled.h3`
  color: gray;
  text-transform: uppercase;
`;

const UpdatePaymentLinkButton = styled(Button)`
  &.ant-btn-link {
    padding: 0;
  }
`;

const SubscriptionLinkButton = styled(Button)`
  &.ant-btn-link {
    padding: 0;
    color: red;
  }

  &.ant-btn-link:hover,
  &.ant-btn-link:focus {
    color: red;
  }
`;

export const Billing: React.FC = () => {
  const [t] = useTranslation();
  const { data: billingInfo, loading, error } = useBilling();
  const { getSessionId, loading: loadingSession } = useStripeSession(CREATE_UPDATE_SESSION, 'createUpdateSession');
  const { activateSubscription, cancelSubscription, loading: loadingSubscriptionUpdates } = useSubscription();

  const invalidPaymentMethodError = isPaymentMethodInvalidError(error);
  const errorMessage = invalidPaymentMethodError ? t('account.invalidPaymentMethod') : t('account.errorBillingInfo');

  if (error) {
    captureException(error);
  }

  const updatePaymentMethods = async (): Promise<void> => {
    try {
      const sessionId = await getSessionId();
      proceedToCheckout(sessionId as string);
    } catch (err) {
      ApiErrorLogger(err);
      message.error(t('account.errorContactPaymentService'));
    }
  };

  const handleSubscriptionCancelation = async () => {
    Modal.confirm({
      title: t('account.toggleSubscription.title'),
      content: t('account.toggleSubscription.content'),
      okText: t('account.toggleSubscription.yes'),
      cancelText: t('account.toggleSubscription.no'),
      centered: true,
      async onOk() {
        try {
          await cancelSubscription();
        } catch (err) {
          ApiErrorLogger(err);
          message.error(t('account.errorCancelSubscription'));
        }
      },
    });
  };

  const handleSubscriptionActivation = async () => {
    try {
      await activateSubscription();
    } catch (err) {
      ApiErrorLogger(err);
      message.error(t('account.errorReSubscribe'));
    }
  };

  // Safely destructuring and placeholders for <Skeleton />
  const brand = propOr('Visa', 'brand')(billingInfo);
  const last4 = propOr('1234', 'last4')(billingInfo);
  const cancelAtPeriodEnd = propOr(false, 'cancelAtPeriodEnd')(billingInfo);
  const currentPeriodEnd: number = propOr(0, 'currentPeriodEnd')(billingInfo);
  const upcomingAmount: number = propOr(9900, 'upcomingAmount')(billingInfo);
  const currency = propOr('brl', 'currency')(billingInfo);

  return (
    <Row>
      <Col xs={24} md={8}>
        <Title>{t('account.paymentMethod')}</Title>
      </Col>
      <Col xs={24} md={16}>
        <Skeleton loading={loading} active>
          <>
            {error ? (
              <InlineErrorMessage message={errorMessage} />
            ) : (
              <>
                <div>
                  <strong style={{ textTransform: 'uppercase' }}>{`${brand} **** **** **** ${last4}`}</strong>
                </div>
                {!cancelAtPeriodEnd && (
                  <div>
                    <span>{t('account.paymentInfo')}</span>
                    <strong style={{ textTransform: 'uppercase' }}>
                      {t('account.price', {
                        currency: t(`account.currency.${currency}`),
                        price: formatPrice(upcomingAmount),
                      })}
                    </strong>
                    <span>{t('account.nextPayment')}</span>
                    <strong>{formatUnixDate(currentPeriodEnd, t('account.dateFormat'))}</strong>.
                  </div>
                )}
              </>
            )}

            <UpdatePaymentLinkButton type="link" loading={loadingSession} onClick={updatePaymentMethods}>
              {t('account.updatePaymentMethod')}
            </UpdatePaymentLinkButton>
            <br />
            {!cancelAtPeriodEnd && (
              <SubscriptionLinkButton
                type="link"
                loading={loadingSubscriptionUpdates}
                disabled={loadingSession}
                onClick={handleSubscriptionCancelation}
              >
                {t('account.cancelSubscription')}
              </SubscriptionLinkButton>
            )}

            {cancelAtPeriodEnd && (
              <SubscriptionLinkButton
                type="link"
                disabled={loadingSession}
                loading={loadingSubscriptionUpdates}
                onClick={handleSubscriptionActivation}
              >
                {t('account.resumeSubscription')}
              </SubscriptionLinkButton>
            )}
          </>
        </Skeleton>
      </Col>
    </Row>
  );
};
