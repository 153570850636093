import { useEffect, useState, createContext, useContext } from 'react';

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  User,
  UserCredential,
  ParsedToken,
} from 'firebase/auth';

import '../Firebase';

import { ApiErrorLogger } from '../ApiErrorLogger';

const provider = new GoogleAuthProvider();

interface AuthContext {
  auth: CustomUser | undefined;
}

export const authContext = createContext<AuthContext>({
  auth: undefined,
});

export const useSession = (): AuthContext => {
  const { auth } = useContext(authContext);
  return { auth };
};

export type CustomClaims = {
  customer?: string;
  subscriptionId?: string;
  subscriptionActive?: boolean;
};

export type CustomUser = (User & { claims?: ParsedToken }) | undefined;

export type AuthState = {
  user: CustomUser;
  loading: boolean;
};

export const useAuth = (): [CustomUser, boolean] => {
  const [state, setState] = useState<AuthState>(() => {
    const user = getUser();
    return {
      loading: !user,
      user: user || undefined,
    } as AuthState;
  });

  async function onChange(user: User | null): Promise<void> {
    if (user) {
      const currentUser = getUser();

      if (currentUser) {
        const { claims } = await currentUser.getIdTokenResult();

        setState({ loading: false, user: { ...user, claims } });
      }
    } else {
      setState({ loading: false, user: undefined });
    }
  }

  useEffect(() => {
    const unsubscribe = getAuth().onIdTokenChanged(onChange);
    return (): void => unsubscribe();
  }, []);

  return [state.user, state.loading];
};

export const signInWithGoogle = async (): Promise<UserCredential> => {
  try {
    const auth = getAuth();
    const credentials = await signInWithPopup(auth, provider);
    return credentials;
  } catch (err) {
    ApiErrorLogger(err);
    throw err;
  }
};

export const signInWithEmailAndPassword = async (email: string, password: string): Promise<UserCredential> => {
  try {
    const auth = getAuth();
    const credentials = await _signInWithEmailAndPassword(auth, email, password);
    return credentials;
  } catch (err) {
    ApiErrorLogger(err);
    throw err;
  }
};

export const createUserWithEmailAndPassword = async (email: string, password: string): Promise<UserCredential> => {
  try {
    const auth = getAuth();
    const credentials = await _createUserWithEmailAndPassword(auth, email, password);
    return credentials;
  } catch (err) {
    ApiErrorLogger(err);
    throw err;
  }
};

export const sendEmailVerification = async (): Promise<void> => {
  try {
    const auth = getAuth();
    auth.useDeviceLanguage();
    if (!auth.currentUser) {
      throw new Error('No user available to send verification email');
    }
    return await _sendEmailVerification(auth.currentUser);
  } catch (err) {
    ApiErrorLogger(err);
    throw err;
  }
};

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  try {
    const auth = getAuth();
    auth.useDeviceLanguage();
    return await _sendPasswordResetEmail(auth, email);
  } catch (err) {
    ApiErrorLogger(err);
    throw err;
  }
};

export const signOut = (): Promise<void> => getAuth().signOut();

export const getUser = (): User | null => getAuth().currentUser;

export const reloadUser = (): Promise<void> | undefined => getUser()?.reload();

export const getNewToken = (): Promise<string> | undefined => getUser()?.getIdToken(true);

export const hardReloadUser = async (): Promise<void> => {
  await reloadUser();
  await getNewToken();
};
