import React from 'react';
import { View } from '@react-pdf/renderer';

type Props = {
  children: React.ReactNode;
};

export const Info: React.FC<Props> = ({ children }) => {
  return (
    <View
      style={{
        backgroundColor: 'lightgray',
        fontSize: 8,
        border: 1,
        padding: 12,
        marginTop: 8,
        marginBottom: 64,
      }}
    >
      {children}
    </View>
  );
};
