import React from 'react';
import styled from 'styled-components';

import image from 'assets/logo.png';

const Title = styled.div`
  font-size: 1.3rem;
  font-family: 'Pacifico', sans-serif;
`;

const ResponsiveImage = styled.img`
  width: 100%;
  height: auto;
`;

const ImageContainer = styled.div`
  width: 48px;
  height: 37px;
  margin-right: 8px;
  display: inline-block;
`;

const BrandContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Brand: React.FC = () => (
  <BrandContainer>
    <ImageContainer>
      <ResponsiveImage src={image} alt="Logo tongue tie" />
    </ImageContainer>
    <Title>Tongue Tie app</Title>
  </BrandContainer>
);

export default Brand;
