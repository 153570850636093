import { gql } from '@apollo/client';

export const GET_COUPON = gql`
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      amount_off
      created
      currency
      duration
      duration_in_months
      max_redemptions
      name
      percent_off
      redeem_by
      times_redeemed
      valid
    }
  }
`;
