import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSession } from 'services/Authentication';
import ROUTES from 'routes';

const shouldRenderComponent = auth => {
  return auth && auth.emailVerified && auth.claims.subscriptionActive;
};

const getRedirectPath = auth => {
  if (!auth) {
    return ROUTES.SIGNIN;
  }

  if (!auth.emailVerified) {
    return ROUTES.VERIFY_EMAIL;
  }

  if (!auth.claims.subscriptionActive) {
    return ROUTES.PAYMENT;
  }

  return ROUTES.SIGNIN;
};

type Props = {
  children: React.ReactElement;
};

const SubscriberRoute: React.FC<Props> = ({ children }) => {
  const { auth } = useSession();
  const location = useLocation();

  if (!shouldRenderComponent(auth)) {
    return (
      <Navigate
        to={{
          pathname: getRedirectPath(auth),
        }}
        state={{ from: location }}
      />
    );
  }
  return children;
};

export default SubscriberRoute;
