import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Form, Button, message, Select, Input } from 'antd';
import enLocale from 'antd/es/date-picker/locale/en_US';
import ptLocale from 'antd/es/date-picker/locale/pt_BR';

import { handleDatepickerChange, handleErrorMessage, handleSelectChange } from 'utils/formik';
import { isFutureDate, parse } from 'utils/date';
import DrawerSpinner from 'components/DrawerSpinner';
import MaskedInput from 'components/Form/MaskedInput';
import DatePicker from 'components/DatePicker';

import { DrawerFooter, DrawerContainer } from '../../components/FixedFooterDrawer';
import { usePatient, usePatientManager } from '../../services/patients';
import { DrawerProps } from '../../components/PatientDrawer';

const getPatientSchema = (t: (k: string) => string) =>
  yup.object().shape({
    name: yup.string().required(t('validation.required')),
    parentNationalId: yup.string().matches(/^((?!_).)*$/, t('validation.nationalId')),
    parentName: yup.string().required(t('validation.required')),
    birth: yup.date().required(t('validation.required')),
    gender: yup
      .string()
      .matches(/(FEMALE|MALE)/)
      .required(t('validation.required')),
    contact: yup.object().shape({
      primaryPhone: yup.string().matches(/^((?!_).)*$/, t('validation.phone')),
      alternativePhone: yup.string().matches(/^((?!_).)*$/, t('validation.phone')),
      email: yup.string().email(t('validation.email')).required(t('validation.required')),
    }),
    address: yup.object().shape({
      street: yup.string(),
      number: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string(),
    }),
  });

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MarginRight = styled.div`
  margin-right: 16px;
`;

const CreatePatientsForm: React.FC<DrawerProps> = ({ close, showConfirm, confirmClose }) => {
  const [t, i18n] = useTranslation();
  const { patientId } = useParams();
  const { create: addPatient, update: updatePatient } = usePatientManager();
  const { data: initialValues, loading, error } = usePatient(patientId);
  const locale = i18n.language === 'en' ? enLocale : ptLocale;

  const validationSchema = useMemo(() => getPatientSchema(t), [t]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: async patient => {
      try {
        if (patientId) {
          await updatePatient(patientId, { ...patient });
        } else {
          await addPatient({ ...patient });
        }
        close();
        message.success(t('success.patient-add'));
      } catch (err) {
        message.error(t('error.patient-add'));
        console.error(err);
      }
    },
  });

  const genderOptions = useMemo(
    () => [
      { value: 'FEMALE', label: t('common.personal.genders.female') },
      { value: 'MALE', label: t('common.personal.genders.male') },
    ],
    [t]
  );

  if (error) {
    message.error(t('error.get-patient'));
  }

  if (loading) {
    return <DrawerSpinner />;
  }

  showConfirm(formik.dirty);

  return (
    <DrawerContainer>
      <Form onFinish={formik.handleSubmit} layout="vertical">
        <h2>{patientId ? t('patient.title.edit') : t('patient.title.add')}</h2>

        <h3>{t('patient.sections.personalInfo')}</h3>

        <Form.Item label={t('common.personal.parentName')} {...handleErrorMessage('parentName', formik)}>
          <Input
            name="parentName"
            placeholder={t('common.personal.placeholder.parentName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.parentName}
          />
        </Form.Item>

        <Form.Item label={t('common.personal.parentNationalId')} {...handleErrorMessage('parentNationalId', formik)}>
          <MaskedInput
            name="parentNationalId"
            mask={t('common.personal.mask.nationalId')}
            placeholder={t('common.personal.placeholder.parentNationalId')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.parentNationalId}
          />
        </Form.Item>

        <Form.Item label={t('common.personal.name')} {...handleErrorMessage('name', formik)}>
          <Input
            name="name"
            placeholder={t('common.personal.placeholder.name')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
        </Form.Item>

        <Form.Item label={t('common.personal.birth')} {...handleErrorMessage('birth', formik)}>
          <DatePicker
            locale={locale}
            format={t('dateFormat')}
            name="birth"
            disabledDate={isFutureDate}
            onChange={handleDatepickerChange('birth', formik)}
            onBlur={formik.handleBlur}
            value={parse(formik.values.birth)}
          />
        </Form.Item>

        <Form.Item label={t('common.personal.gender')} {...handleErrorMessage('gender', formik)}>
          <Select
            options={genderOptions}
            onChange={handleSelectChange('gender', formik)}
            onBlur={formik.handleBlur}
            value={formik.values.gender}
          />
        </Form.Item>

        <h3>{t('patient.sections.contact')}</h3>

        <Form.Item label={t('common.personal.contact.email')} {...handleErrorMessage('contact.email', formik)}>
          <Input
            name="contact.email"
            placeholder={t('common.personal.placeholder.contact.email')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact.email}
          />
        </Form.Item>

        <Form.Item
          label={t('common.personal.contact.primaryPhone')}
          {...handleErrorMessage('contact.primaryPhone', formik)}
        >
          <MaskedInput
            name="contact.primaryPhone"
            placeholder={t('common.personal.placeholder.contact.primaryPhone')}
            mask={t('common.personal.mask.phone')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact.primaryPhone}
          />
        </Form.Item>

        <Form.Item
          label={t('common.personal.contact.alternativePhone')}
          {...handleErrorMessage('contact.alternativePhone', formik)}
        >
          <MaskedInput
            name="contact.alternativePhone"
            placeholder={t('common.personal.placeholder.contact.alternativePhone')}
            mask={t('common.personal.mask.phone')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contact.alternativePhone}
          />
        </Form.Item>

        <h3>{t('patient.sections.address')}</h3>

        <Form.Item label={t('common.address.number')} {...handleErrorMessage('address.number', formik)}>
          <Input
            name="address.number"
            placeholder="1356"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.number}
          />
        </Form.Item>

        <Form.Item label={t('common.address.street')} {...handleErrorMessage('address.street', formik)}>
          <Input
            name="address.street"
            placeholder={t('common.address.placeholder.street')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.street}
          />
        </Form.Item>

        <Form.Item label={t('common.address.city')} {...handleErrorMessage('address.city', formik)}>
          <Input
            name="address.city"
            placeholder={t('common.address.placeholder.city')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.city}
          />
        </Form.Item>

        <Form.Item label={t('common.address.state')} {...handleErrorMessage('address.state', formik)}>
          <Input
            name="address.state"
            placeholder={t('common.address.placeholder.city')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.state}
          />
        </Form.Item>

        <Form.Item label={t('common.address.zip')} {...handleErrorMessage('address.zip', formik)}>
          <Input
            name="address.zip"
            placeholder={t('common.address.placeholder.zip')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.zip}
          />
        </Form.Item>

        <DrawerFooter>
          <ButtonsContainer>
            <MarginRight>
              <Button type="default" onClick={confirmClose}>
                {t('patient.cancel')}
              </Button>
            </MarginRight>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!formik.isValid || !formik.dirty}
                loading={formik.isSubmitting}
              >
                {t('patient.save')}
              </Button>
            </Form.Item>
          </ButtonsContainer>
        </DrawerFooter>
      </Form>
    </DrawerContainer>
  );
};

export default CreatePatientsForm;
