import styled from 'styled-components';

export const DrawerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  padding: 8px 16px;
  z-index: 15;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
`;

export const DrawerContainer = styled.div`
  margin-bottom: 48px;
`;
