import React from 'react';
import { Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { Patient } from 'modules/Main/Patients/types';
import CompleteReportHeader from '../Header';
import { Options, Option } from './components/Options';
import {
  lipPosture,
  tonguePosture,
  apexShape,
  lingualFrenulum,
  frenulumThickness,
  frenulumTongueAttach,
  frenulumFloorAttach,
} from './options';
import { Section } from './components/Section';
import { Observation } from './components/Observation';
import { Info } from './components/Info';

const styles = StyleSheet.create({
  page: {
    padding: '12 20 12 20',
    fontSize: 10,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  footer: {
    fontSize: 10,
    margin: 10,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

type Props = {
  patient: Patient;
};

const AnatomyExamPage: React.FC<Props> = ({ patient }) => {
  const [t] = useTranslation();
  const { anatomyExam } = patient;

  const isChecked = (optionValue: string, patientValue: string | undefined): boolean => optionValue === patientValue;

  return (
    <>
      <Page size="A4" style={styles.page}>
        <CompleteReportHeader
          examTitle={t('reports.anatomyExam.title')}
          partTitle={t('reports.anatomyExam.subtitle')}
        />
        <View style={{ border: '1px solid black', marginBottom: 12, padding: 12 }}>
          <Section title={t('reports.anatomyExam.lipPosture.title')}>
            <Options>
              {lipPosture.map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.lipPosture)}
                  />
                );
              })}
            </Options>
          </Section>

          <Section title={t('reports.anatomyExam.tonguePosture.title')}>
            <Options>
              {tonguePosture.slice(0, 2).map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.tonguePosture)}
                  />
                );
              })}
            </Options>
            <Options>
              {tonguePosture.slice(2, 4).map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.tonguePosture)}
                  />
                );
              })}
            </Options>
          </Section>

          <Section title={t('reports.anatomyExam.apexShape.title')}>
            <Options>
              {apexShape.map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.apexShape)}
                  />
                );
              })}
            </Options>
          </Section>
        </View>

        <View style={styles.footer} fixed>
          <Text>{t('reports.footer.copyright')}</Text>
        </View>
      </Page>

      <Page size="A4" style={styles.page}>
        <CompleteReportHeader />
        <View style={{ border: '1px solid black', marginBottom: 12, padding: 12 }}>
          <Section title={t('reports.anatomyExam.lingualFrenulum.title')}>
            <Options>
              {lingualFrenulum.map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.lingualFrenulum)}
                    showCount={false}
                  />
                );
              })}
            </Options>
            <Observation>{t('reports.anatomyExam.lingualFrenulum.observation1')}</Observation>
            <Observation>{t('reports.anatomyExam.lingualFrenulum.observation2')}</Observation>
          </Section>

          <Section title={t('reports.anatomyExam.frenulumThickness.title')}>
            <Options>
              {frenulumThickness.map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.frenulumThickness)}
                  />
                );
              })}
            </Options>
          </Section>

          <Section title={t('reports.anatomyExam.frenulumTongueAttach.title')}>
            <Options>
              {frenulumTongueAttach.map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.frenulumTongueAttach)}
                  />
                );
              })}
            </Options>
          </Section>

          <Section title={t('reports.anatomyExam.frenulumFloorAttach.title')}>
            <Options>
              {frenulumFloorAttach.map(option => {
                return (
                  <Option
                    key={option.label}
                    src={option.src}
                    text={t(option.label)}
                    count={option.count}
                    checked={isChecked(option.value, anatomyExam?.frenulumFloorAttach)}
                  />
                );
              })}
            </Options>
            <Text style={{ textAlign: 'center' }}>{t('reports.anatomyExam.results')}</Text>
            <Info>
              <Text>{t('reports.anatomyExam.info')}</Text>
            </Info>
          </Section>
        </View>
        <View style={styles.footer} fixed>
          <Text>{t('reports.footer.copyright')}</Text>
        </View>
      </Page>
    </>
  );
};

export default AnatomyExamPage;
