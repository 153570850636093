import { useMemo } from 'react';
import routes from 'routes';
import { Patient, EXAM, ResultType } from '../../types';

const isDoubtful = (score: number): boolean => score >= 5 && score <= 6;

export const getNextApplicableExam = (patient: Patient): EXAM | undefined => {
  if (
    !patient.anatomyExam ||
    !patient.examinationResults ||
    patient.examinationResults.name === ResultType.NOT_EXAMINED
  ) {
    return EXAM.ANATOMY_EXAM;
  }

  if (isDoubtful(patient.anatomyExam.score || 0)) {
    if (!patient.suckingExam) return EXAM.SUCKING_EXAM;
    if (!patient.clinicalHistoryExam) return EXAM.CLINICAL_HISTORY_EXAM;
  }

  return undefined;
};

export const getAppliedExams = (patient: Patient): EXAM[] => {
  const exams: EXAM[] = [];

  if (patient.anatomyExam) exams.push(EXAM.ANATOMY_EXAM);
  if (patient.suckingExam) exams.push(EXAM.SUCKING_EXAM);
  if (patient.clinicalHistoryExam) exams.push(EXAM.CLINICAL_HISTORY_EXAM);

  return exams;
};

export const useAppliedExams = (patient: Patient): EXAM[] => {
  return useMemo(() => getAppliedExams(patient), [patient]);
};

export const EXAM_META = {
  [EXAM.ANATOMY_EXAM]: { i18nKey: 'patient.exams.anatomyExam', link: routes.PATIENTS_ANATOMY_EXAM },
  [EXAM.SUCKING_EXAM]: { i18nKey: 'patient.exams.suckingExam', link: routes.PATIENTS_SUCKING_EXAM },
  [EXAM.CLINICAL_HISTORY_EXAM]: {
    i18nKey: 'patient.exams.clinicalHistoryExam',
    link: routes.PATIENTS_CLINICAL_HISTORY_EXAM,
  },
};

export const getExaminationBoxType = (
  examinationResults = ResultType.NOT_EXAMINED
): 'success' | 'info' | 'warning' | 'error' => {
  switch (examinationResults) {
    case ResultType.ALTERED:
      return 'error';
    case ResultType.NORMAL:
      return 'success';
    case ResultType.DOUBTFUL:
      return 'warning';
    case ResultType.NOT_EXAMINED:
      return 'info';
    default:
      break;
  }
  return 'info';
};
