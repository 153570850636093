import React from 'react';
import { Text } from '@react-pdf/renderer';

interface Props {
  children: React.ReactNode;
}

export const Observation: React.FC<Props> = ({ children }) => {
  return <Text style={{ fontSize: 8, paddingBottom: 8 }}>{children}</Text>;
};
