import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { LockOutlined } from '@ant-design/icons';
import { Form, Button, message, Card, Row, Col, Input, Checkbox } from 'antd';
import { object, string, boolean, ref } from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import routes from 'routes';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'services/Authentication';
import { ApiErrorLogger } from 'services/ApiErrorLogger';
import { handleErrorMessage } from 'utils/formik';
import LanguageHeader from 'components/LanguageHeader';
import background from 'assets/background.jpg';
import PrivacyAndTerms from 'components/PrivacyAndTerms';
import GoogleSignIn from 'components/GoogleSignIn';

function getSignUpSchema(t: (k: string, v?: unknown) => string) {
  return object().shape({
    email: string().email(t('validation.email')).required(t('validation.required')),
    password: string()
      .min(8, ({ min }) => t('validation.min', { min }))
      .required(t('validation.required')),
    passwordConfirm: string()
      .oneOf([ref('password')], t('validation.matchingPassword'))
      .required(t('validation.required')),
    termsAndConditions: boolean().required(t('validation.required')).oneOf([true], t('validation.required')),
  });
}

const CenteredRow = styled(Row)`
  min-height: 100vh;
  background-color: #1890ff;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
`;

const SignUp: React.FC = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const initialValues = { password: '', email: '', passwordConfirm: '', termsAndConditions: false };
  const validationSchema = useMemo(() => getSignUpSchema(t), [t]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async values => {
      try {
        await createUserWithEmailAndPassword(values.email, values.password);
        await sendEmailVerification();
        navigate(routes.MAIN, { state: { from: location } });
      } catch (error) {
        message.error(t('error.signup'));
        ApiErrorLogger(error);
      }
    },
  });

  return (
    <CenteredRow justify="center">
      <Col xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
        <Card title={<LanguageHeader />}>
          <Form onFinish={formik.submitForm} layout="vertical">
            <Form.Item label={t('signup.form.email')} {...handleErrorMessage('email', formik)}>
              <Input
                name="email"
                placeholder={t('signup.placeholder.email')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
            <Form.Item label={t('signup.form.password')} {...handleErrorMessage('password', formik)}>
              <Input
                type="password"
                name="password"
                placeholder={t('signup.placeholder.password')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>
            <Form.Item label={t('signup.form.passwordConfirm')} {...handleErrorMessage('passwordConfirm', formik)}>
              <Input
                type="password"
                name="passwordConfirm"
                placeholder={t('signup.placeholder.passwordConfirm')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </Form.Item>
            <Form.Item {...handleErrorMessage('termsAndConditions', formik)}>
              <Checkbox name="termsAndConditions" onChange={formik.handleChange}>
                <PrivacyAndTerms />
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                disabled={!formik.isValid || !formik.dirty}
                loading={formik.isSubmitting}
              >
                {t('signup.form.submit')}
              </Button>
            </Form.Item>
            <GoogleSignIn label={t('signup.form.googleSignUp')} />
          </Form>
        </Card>
      </Col>
    </CenteredRow>
  );
};

export default SignUp;
