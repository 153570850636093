import React from 'react';
import { Typography, Descriptions, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';

import { Patient } from 'modules/Main/Patients/types';

interface Props {
  patient: Patient;
  onDetailsClick: () => void;
  onRemoveClick: () => void;
}

const MarginBottomBlock = styled.div`
  margin-bottom: 16px;
`;

const PatientSummary: React.FC<Props> = ({ patient, onDetailsClick, onRemoveClick }) => {
  const [t] = useTranslation();
  return (
    <>
      <Typography.Title level={3}>{t('actions.patient-options')}</Typography.Title>
      <Descriptions layout="horizontal">
        <Descriptions.Item label={t('actions.name')} span={3}>
          {patient.name}
        </Descriptions.Item>
        <Descriptions.Item label={t('actions.gender')} span={3}>
          {t(`gender.${patient.gender}`)}
        </Descriptions.Item>
        <Descriptions.Item label={t('actions.birth')} span={3}>
          {moment(patient.birth).format(t('dateFormat'))}
        </Descriptions.Item>
        <Descriptions.Item label={t('actions.parentName')} span={3}>
          {patient.parentName}
        </Descriptions.Item>
        <Descriptions.Item label={t('actions.parentNationalId')} span={3}>
          {patient.parentNationalId}
        </Descriptions.Item>
        <Descriptions.Item label={t('actions.email')} span={3}>
          {patient.contact.email}
        </Descriptions.Item>
      </Descriptions>
      <Row gutter={[24, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <MarginBottomBlock>
            <Button type="default" block onClick={onDetailsClick}>
              {t('actions.edit-patient')}
            </Button>
          </MarginBottomBlock>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <MarginBottomBlock>
            <Button danger block onClick={onRemoveClick}>
              {t('actions.delete-patient')}
            </Button>
          </MarginBottomBlock>
        </Col>
      </Row>
    </>
  );
};

export default PatientSummary;
