import { useMemo } from 'react';
import { Coupon } from './services/coupon/types';
import { Plan } from './services/plan/types';

type CustomCoupom = {
  price: string;
  currency: string;
  amountOff: string;
  originalPrice: string;
  percentOff: number;
  isCouponValid: boolean;
  hasCoupon: boolean;
  durationInMonths: number;
};

export function useCouponValues(plan: Plan, coupon: Coupon | null): CustomCoupom {
  const isAmountCoupon = coupon?.amount_off !== null;

  const toPrice = (amount: number): string => (amount / 100).toFixed(2);

  return useMemo(() => {
    const { amount, currency } = plan;

    if (!coupon) {
      const price = toPrice(amount);
      return {
        price,
        currency,
        hasCoupon: false,
        isCouponValid: false,
        amountOff: '',
        originalPrice: '',
        percentOff: 0,
        durationInMonths: 0,
      };
    }

    if (!coupon.valid || (coupon.currency && plan.currency !== coupon.currency)) {
      const price = toPrice(amount);
      return {
        price,
        currency,
        hasCoupon: true,
        isCouponValid: false,
        amountOff: '',
        originalPrice: '',
        percentOff: 0,
        durationInMonths: 0,
      };
    }

    const {
      amount_off: amountOff,
      duration_in_months: durationInMonths,
      percent_off: percentOff,
      valid: isCouponValid,
    } = coupon;

    if (isAmountCoupon) {
      return {
        price: toPrice(amount - amountOff),
        currency,
        amountOff: toPrice(amountOff),
        originalPrice: toPrice(amount),
        percentOff,
        isCouponValid,
        hasCoupon: true,
        durationInMonths: 0,
      };
    }

    const calculatedAmountOff = Math.ceil((amount * percentOff) / 100);

    return {
      price: toPrice(amount - calculatedAmountOff),
      currency,
      amountOff: toPrice(calculatedAmountOff),
      originalPrice: toPrice(amount),
      percentOff,
      isCouponValid,
      hasCoupon: true,
      durationInMonths,
    };
  }, [plan, coupon, isAmountCoupon]);
}
