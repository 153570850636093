import React from 'react';
import { pathOr } from 'ramda';
import { FieldArray, FormikProvider } from 'formik';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

// @todo: find out why ResultType<typeof useFormik> could not type formik
export const Alterations = ({ formik }) => {
  const [t] = useTranslation();
  const hasAlterations = Boolean(pathOr(0, ['values', 'familyHistory', 'alterations', 'length'], formik) > 0);
  const canAddItems = (pathOr(0, ['values', 'familyHistory', 'alterations', 'length'], formik) as number) < 3;
  const canRemoveItems = (pathOr(0, ['values', 'familyHistory', 'alterations', 'length'], formik) as number) > 1;

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="familyHistory.alterations"
        render={arrayHelpers => {
          const addItem = () => {
            if (canAddItems) {
              return arrayHelpers.push('');
            }
          };

          const removeItem = (index: number) => () => {
            if (canRemoveItems) {
              return arrayHelpers.remove(index);
            }
          };

          return (
            <>
              {hasAlterations &&
                formik.values.familyHistory.alterations.map((_: string, index: number) => (
                  <Form.Item key={index}>
                    <Input.Group compact>
                      <Input
                        addonBefore={t('clinicalHistoryExam.familyHistory.alterations.who')}
                        style={{ width: 'calc(50% - 16px)' }}
                        name={`familyHistory.alterations[${index}].who`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.familyHistory.alterations[index].who}
                      />
                      <Input
                        addonBefore={t('clinicalHistoryExam.familyHistory.alterations.what')}
                        style={{ width: 'calc(50% - 16px)' }}
                        name={`familyHistory.alterations[${index}].what`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.familyHistory.alterations[index].what}
                      />
                      <Button danger icon={<DeleteOutlined />} onClick={removeItem(index)} disabled={!canRemoveItems} />
                    </Input.Group>
                  </Form.Item>
                ))}
              <Form.Item>
                <Button block type="dashed" icon={<PlusOutlined />} onClick={addItem} disabled={!canAddItems}>
                  {t('clinicalHistoryExam.familyHistory.alterations.add')}
                </Button>
              </Form.Item>
            </>
          );
        }}
      />
    </FormikProvider>
  );
};
