import { useCallback } from 'react';
import { useMutation, DocumentNode, OperationVariables } from '@apollo/client';
import Stripe from '.';
import { path } from 'ramda';

export const useStripeSession = (sessionQuery: DocumentNode, key: string) => {
  const [createSession, { loading }] = useMutation(sessionQuery);

  const getSessionId = useCallback(
    async (variables?: OperationVariables) => {
      const session = await createSession({ variables });
      return path(['data', key, 'id'], session);
    },
    [createSession]
  );

  return { getSessionId, loading };
};

export const proceedToCheckout = async (sessionId: string) => {
  return await Stripe.redirectToCheckout({
    sessionId,
  });
};

// @ts-ignore
export default window.Stripe(process.env.REACT_APP_STRIPE_KEY);
