import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { GET_COUPON } from './graphql';
import { CouponData } from './types';

const COUPON_KEY = 'coupon';

export const useCouponValidation = () => {
  const [serarchParams, setSearchParams] = useSearchParams();
  const codeFromUrl = serarchParams.get(COUPON_KEY) || '';
  const [code, setCode] = useState(codeFromUrl);
  const { data, loading, error, refetch } = useQuery<CouponData>(GET_COUPON, {
    variables: { id: code },
    skip: !code,
    fetchPolicy: 'network-only',
  });

  const refetchCoupon = useCallback((code?: string) => {
    if (typeof code !== 'string') {
      refetch();
      return;
    }
    setCode(code);
  }, []);

  useEffect(() => {
    if (data && data.getCoupon.valid) {
      setSearchParams({ [COUPON_KEY]: code });
      return;
    }
    setSearchParams({});
  }, [data]);

  return { data: data ? data.getCoupon : null, loading, error, refetch: refetchCoupon };
};
