import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import { Billing } from './components/Billing';
import { Profile } from './components/Profile';

const Section = styled.div`
  border-top: solid 1px lightgray;
  margin-bottom: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Account: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div>
      <h2>{t('account.title')}</h2>
      <Section>
        <Profile />
      </Section>

      <Section>
        <Billing />
      </Section>
    </div>
  );
};

export default Account;
