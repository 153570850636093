import { useQuery } from '@apollo/client';
import { RETRIEVE_PLAN } from './graphql';
import { PlanData } from './types';

export const usePlan = (locale: string) => {
  const { data, loading, error } = useQuery<PlanData>(RETRIEVE_PLAN, { variables: { locale } });

  return {
    data: data ? data.retrievePlan : null,
    loading,
    error,
  };
};
