import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/lib/modal';
import { EXAM } from '../types';

const i18nExamMap = (t: (k: string) => string) => {
  return {
    [EXAM.ANATOMY_EXAM]: {
      title: t('remove-exam.anatomy.title'),
      content: t('remove-exam.anatomy.content'),
      okText: t('remove-exam.anatomy.okText'),
      cancelText: t('remove-exam.anatomy.cancelText'),
    },
    [EXAM.SUCKING_EXAM]: {
      title: t('remove-exam.sucking.title'),
      content: t('remove-exam.sucking.content'),
      okText: t('remove-exam.sucking.okText'),
      cancelText: t('remove-exam.sucking.cancelText'),
    },
    [EXAM.CLINICAL_HISTORY_EXAM]: {
      title: t('remove-exam.clinical-history.title'),
      content: t('remove-exam.clinical-history.content'),
      okText: t('remove-exam.clinical-history.okText'),
      cancelText: t('remove-exam.clinical-history.cancelText'),
    },
  };
};

const removeExam = (onOk: ModalFuncProps['onOk'], exam: EXAM, t: (k: string) => string): void => {
  Modal.confirm({
    ...i18nExamMap(t)[exam],
    okType: 'danger',
    maskClosable: true,
    centered: true,
    onOk,
  });
};

export default removeExam;
