import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Col, Row, message } from 'antd';
import { Navigate, useLocation } from 'react-router-dom';

import { hardReloadUser, useSession } from 'services/Authentication';
import routes from 'routes';
import Spinner from 'components/Spinner';
import LanguageHeader from 'components/LanguageHeader';
import { ApiErrorLogger } from 'services/ApiErrorLogger';
import PlanDetails from './components/PlanDetails';
import PlanFeatures from './components/PlanFeatures';
import { usePlan } from './services/plan';
import { useCouponValidation } from './services/coupon';
import { proceedToCheckout, useStripeSession } from 'services/Stripe';
import { CREATE_SESSION } from 'modules/Payment/services/plan/graphql';
import { CreateSessionVars } from 'modules/Payment/services/plan/types';

const Container = styled(Row)`
  background: #f2f2f2;
`;

const BrandContainer = styled.div`
  padding: 24px 16px;
`;

export default function Payment() {
  const { auth } = useSession();
  const [t] = useTranslation();
  const location = useLocation();
  const [locale, setLocale] = useState(i18n.language);
  const [loading, setLoading] = useState(false);

  const { data: plan, loading: loadingPlan } = usePlan(locale);
  const { data: coupon, loading: loadingCoupon, error: couponError, refetch: refetchCoupon } = useCouponValidation();
  const { getSessionId, loading: loadingSession } = useStripeSession(CREATE_SESSION, 'createSession');

  const setCoupon = (name: string) => {
    refetchCoupon(name.trim());
  };

  const clearCoupon = () => {
    refetchCoupon('');
  };

  const changeLocale = async newLocale => {
    setLocale(newLocale);
    refetchCoupon(coupon?.id);
  };

  useEffect(() => {
    async function fetchNewToken() {
      setLoading(true);
      await hardReloadUser();
      setLoading(false);
    }
    fetchNewToken();
  }, []);

  if (loading || loadingPlan || !plan) {
    return <Spinner />;
  }

  if (auth?.claims?.subscriptionActive) {
    return <Navigate to={routes.MAIN} state={{ from: location }} />;
  }

  const redirectToPaymentPage = async () => {
    try {
      const options: CreateSessionVars = { locale };
      if (coupon && coupon.valid && (!coupon.currency || coupon.currency === plan.currency)) {
        options.couponId = coupon.id;
      }
      const sessionId = await getSessionId(options);
      proceedToCheckout(sessionId as string);
    } catch (e) {
      message.error(t('plan.checkoutError'));
      ApiErrorLogger(e);
    }
  };

  return (
    <Container>
      <Col xs={24}>
        <BrandContainer>
          <LanguageHeader />
        </BrandContainer>
      </Col>

      <Col xs={24} md={12}>
        <PlanFeatures />
      </Col>

      <Col xs={24} md={12}>
        <PlanDetails
          plan={plan}
          coupon={coupon}
          redirectToPaymentPage={redirectToPaymentPage}
          preparingCheckout={loadingSession}
          getCoupon={setCoupon}
          clearCoupon={clearCoupon}
          loadingCoupon={loadingCoupon}
          hasErrors={Boolean(couponError)}
          changeLocale={changeLocale}
          locale={locale}
        />
      </Col>
    </Container>
  );
}
