import React from 'react';
import styled from 'styled-components';
import Brand from 'components/Brand';
import LanguageSelector from 'components/LanguageSelector';

const Bar = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const UnsignedHeader: React.FC = () => (
  <Bar>
    <Brand />
    <LanguageSelector />
  </Bar>
);

export default UnsignedHeader;
