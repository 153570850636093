import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import i18next from 'i18next';
import React, { MouseEventHandler } from 'react';
import { generatePath, NavigateFunction } from 'react-router-dom';
import ROUTES from 'routes';
import { getNextApplicableExam } from '../../drawers/Actions/utils';
import { EXAM, Patient } from '../../types';

type Props = {
  patient: Patient;
  navigate: NavigateFunction;
};

export const ContextButton: React.FC<Props> = ({ patient, navigate }) => {
  const nextExam = getNextApplicableExam(patient);
  const onContextButtonClick =
    (to: string): MouseEventHandler<HTMLElement> =>
    e => {
      navigate(generatePath(to, { patientId: patient.uid }));
      e.stopPropagation();
    };

  const LinkButton = ({ to, children }: { to: string; children: React.ReactNode }) => (
    <Button size="small" type="dashed" onClick={onContextButtonClick(to)}>
      <InfoCircleOutlined />
      <span>{children}</span>
    </Button>
  );

  const AnatomyExamButton = () => (
    <LinkButton to={ROUTES.PATIENTS_ANATOMY_EXAM}>{i18next.t('patient.contextButton.anatomyExam')}</LinkButton>
  );
  const SuckingExamButton = () => (
    <LinkButton to={ROUTES.PATIENTS_SUCKING_EXAM}>{i18next.t('patient.contextButton.suckingExam')}</LinkButton>
  );
  const ClinicalHistoryExamButton = () => (
    <LinkButton to={ROUTES.PATIENTS_CLINICAL_HISTORY_EXAM}>
      {i18next.t('patient.contextButton.clinicalHistoryExam')}
    </LinkButton>
  );

  switch (nextExam) {
    case EXAM.ANATOMY_EXAM:
      return <AnatomyExamButton />;
    case EXAM.SUCKING_EXAM:
      return <SuckingExamButton />;
    case EXAM.CLINICAL_HISTORY_EXAM:
      return <ClinicalHistoryExamButton />;

    default:
      break;
  }

  return null;
};
