import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Input, Button, Typography } from 'antd';

import { signOut } from 'services/Authentication';
import PriceDetails from './PriceDetails';
import { useCouponValues } from '../hooks';
import CouponDetails from './CouponDetails';
import CurrencySelector from './CurrencySelector';
import { Coupon } from '../services/coupon/types';
import { Plan } from '../services/plan/types';

const { Search } = Input;
const { Text } = Typography;

const Container = styled.div`
  margin-right: auto;
  padding: 24px 16px;
  max-width: 460px;
`;

const SpacedContent = styled.div`
  margin: auto;
  margin-top: 16px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Note = styled.div`
  font-style: italic;
  text-align: center;
`;

interface Props {
  coupon: Coupon | null;
  plan: Plan;
  redirectToPaymentPage: () => void;
  preparingCheckout: boolean;
  getCoupon: (couponName: string) => void;
  clearCoupon: () => void;
  loadingCoupon: boolean;
  hasErrors: boolean;
  changeLocale: (locale: string) => void;
  locale: string;
}

const PlanDetails: React.FC<Props> = ({
  plan,
  coupon,
  redirectToPaymentPage,
  preparingCheckout,
  getCoupon,
  clearCoupon,
  loadingCoupon,
  hasErrors,
  changeLocale,
  locale,
}) => {
  const [t] = useTranslation();
  const { price, currency, originalPrice, amountOff, percentOff, hasCoupon, isCouponValid, durationInMonths } =
    useCouponValues(plan, coupon);

  const showCouponInput = !isCouponValid || hasErrors || !hasCoupon;
  const couponHasError = hasErrors || (hasCoupon && !isCouponValid);
  const showDiscountBox = isCouponValid && !hasErrors && hasCoupon;

  return (
    <Container>
      <div>
        <h1>{t('plan.title')}</h1>
        <div>{t('plan.description')}</div>
        <SpacedContent>
          {showCouponInput && (
            <>
              <h3>{t('plan.labelCoupon')}</h3>
              <Search
                placeholder={t('plan.placeholderCoupon')}
                onSearch={getCoupon}
                loading={loadingCoupon}
                enterButton={t('plan.applyCoupon')}
              />
              {couponHasError && <Text type="danger">{t('plan.couponInvalid')}</Text>}
            </>
          )}
          {showDiscountBox && (
            <CouponDetails
              clearCoupon={clearCoupon}
              amountOff={amountOff}
              percentOff={percentOff}
              durationInMonths={durationInMonths}
              currency={currency}
            />
          )}
        </SpacedContent>
      </div>

      <PriceDetails
        price={price}
        currency={currency}
        originalPrice={originalPrice}
        isCouponValid={isCouponValid}
        amountOff={amountOff}
      />

      <CurrencySelector setLocale={changeLocale} locale={locale} />

      <Actions>
        <Button
          size="large"
          type="primary"
          onClick={redirectToPaymentPage}
          loading={preparingCheckout}
          disabled={preparingCheckout}
        >
          {t('plan.buyNow')}
        </Button>
        <Button size="large" onClick={signOut} disabled={preparingCheckout}>
          {t('plan.notNow')}
        </Button>
      </Actions>
      <Note>{t('plan.note')}</Note>
    </Container>
  );
};

export default PlanDetails;
