import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import { Button, Typography, Alert } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { EXAM } from 'modules/Main/Patients/types';
import { EXAM_META } from '../utils';

const MarginTopBlock = styled.div`
  margin-top: 16px;
`;

interface NotExaminedProps {
  patientId: string;
}

const NotExamined: React.FC<NotExaminedProps> = ({ patientId }) => {
  const [t] = useTranslation();
  return (
    <div>
      <Typography.Title level={3}>{t('actions.exams')}</Typography.Title>
      <Alert
        type="info"
        message={t('actions.notExamined.title')}
        description={
          <MarginTopBlock>
            <Link to={generatePath(EXAM_META[EXAM.ANATOMY_EXAM].link, { patientId })}>
              <Button type="default" size="small">
                {t(EXAM_META[EXAM.ANATOMY_EXAM].i18nKey)}
                <RightOutlined />
              </Button>
            </Link>
          </MarginTopBlock>
        }
      />
    </div>
  );
};

export default NotExamined;
