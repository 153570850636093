import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { Drawer, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  children: React.ReactElement;
  redirectRoute: string;
};

const DrawerRoute: React.FC<Props> = ({ children, redirectRoute }) => {
  const [t] = useTranslation();
  const [preventClose, setPreventClose] = useState(false);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const isExtraSmall = useMedia('(max-width: 480px)');

  const width = isExtraSmall ? '90%' : '650px';

  const confirmClose = () => {
    if (preventClose) {
      Modal.confirm({
        title: t('drawer.confirmation.title'),
        content: t('drawer.confirmation.content'),
        okText: t('drawer.confirmation.yes'),
        cancelText: t('drawer.confirmation.no'),
        centered: true,
        onOk() {
          setVisible(false);
        },
      });
      return;
    }
    setVisible(false);
  };

  const close = () => setVisible(false);

  const afterVisibleChange = (currentVisible: boolean) => {
    if (!currentVisible) {
      navigate(redirectRoute);
    }
  };

  return (
    <Drawer open={visible} afterOpenChange={afterVisibleChange} onClose={confirmClose} width={width}>
      {React.cloneElement(children, { confirmClose, close, showConfirm: setPreventClose })}
    </Drawer>
  );
};

export default DrawerRoute;
