import closed from 'assets/lipPosture/closed.jpg';
import halfOpen from 'assets/lipPosture/half-open.jpg';
import open from 'assets/lipPosture/open.jpg';

import midline from 'assets/tonguePosture/midline.jpg';
import elevated from 'assets/tonguePosture/elevated.jpg';
import midlineLateral from 'assets/tonguePosture/midline-lateral.jpg';
import apexDown from 'assets/tonguePosture/apex-down.jpg';

import round from 'assets/apexShape/round.jpg';
import vShaped from 'assets/apexShape/v-shaped.jpg';
import heartShaped from 'assets/apexShape/heart-shaped.jpg';

import visible from 'assets/lingualFrenulum/visible.jpg';
import notVisible from 'assets/lingualFrenulum/not-visible.jpg';
import visibleManeuver from 'assets/lingualFrenulum/visible-maneuver.jpg';

import thin from 'assets/frenulumThickness/thin.jpg';
import thick from 'assets/frenulumThickness/thick.jpg';

import frenulumAttachMidline from 'assets/frenulumTongueAttach/midline.jpg';
import midlineApex from 'assets/frenulumTongueAttach/midline-apex.jpg';
import apex from 'assets/frenulumTongueAttach/apex.jpg';

import sublingualCaruncles from 'assets/frenulumFloorAttach/sublingual-caruncles.jpg';
import inferiorAlveolar from 'assets/frenulumFloorAttach/inferior-alveolar.jpg';
import { anatomyExamScoreMap } from 'modules/Main/Patients/utils/score';

export const lipPosture = [
  {
    value: 'CLOSED',
    label: 'reports.anatomyExam.lipPosture.closed',
    src: closed,
    count: anatomyExamScoreMap.lipPosture.CLOSED,
  },
  {
    value: 'HALF_OPEN',
    label: 'reports.anatomyExam.lipPosture.halfOpen',
    src: halfOpen,
    count: anatomyExamScoreMap.lipPosture.HALF_OPEN,
  },
  {
    value: 'OPEN',
    label: 'reports.anatomyExam.lipPosture.open',
    src: open,
    count: anatomyExamScoreMap.lipPosture.OPEN,
  },
];

export const tonguePosture = [
  {
    value: 'MIDLINE',
    label: 'reports.anatomyExam.tonguePosture.midline',
    src: midline,
    count: anatomyExamScoreMap.tonguePosture.MIDLINE,
  },
  {
    value: 'ELEVATED',
    label: 'reports.anatomyExam.tonguePosture.elevated',
    src: elevated,
    count: anatomyExamScoreMap.tonguePosture.ELEVATED,
  },
  {
    value: 'MIDLINE_LATERAL',
    label: 'reports.anatomyExam.tonguePosture.midlineLateral',
    src: midlineLateral,
    count: anatomyExamScoreMap.tonguePosture.MIDLINE_LATERAL,
  },
  {
    value: 'APEX_DOWN',
    label: 'reports.anatomyExam.tonguePosture.apexDown',
    src: apexDown,
    count: anatomyExamScoreMap.tonguePosture.APEX_DOWN,
  },
];

export const apexShape = [
  {
    value: 'ROUND',
    label: 'reports.anatomyExam.apexShape.round',
    src: round,
    count: anatomyExamScoreMap.apexShape.ROUND,
  },
  {
    value: 'V_SHAPED',
    label: 'reports.anatomyExam.apexShape.vShaped',
    src: vShaped,
    count: anatomyExamScoreMap.apexShape.V_SHAPED,
  },
  {
    value: 'HEART_SHAPED',
    label: 'reports.anatomyExam.apexShape.heartShaped',
    src: heartShaped,
    count: anatomyExamScoreMap.apexShape.HEART_SHAPED,
  },
];

export const lingualFrenulum = [
  {
    value: 'VISIBLE',
    label: 'reports.anatomyExam.lingualFrenulum.visible',
    src: visible,
    count: anatomyExamScoreMap.lingualFrenulum.VISIBLE,
  },
  {
    value: 'NOT_VISIBLE',
    label: 'reports.anatomyExam.lingualFrenulum.notVisible',
    src: notVisible,
    count: anatomyExamScoreMap.lingualFrenulum.NOT_VISIBLE,
  },
  {
    value: 'VISIBLE_MANEUVER',
    label: 'reports.anatomyExam.lingualFrenulum.visibleManeuver',
    src: visibleManeuver,
    count: anatomyExamScoreMap.lingualFrenulum.VISIBLE_MANEUVER,
  },
];

export const frenulumThickness = [
  {
    value: 'THIN',
    label: 'reports.anatomyExam.frenulumThickness.thin',
    src: thin,
    count: anatomyExamScoreMap.frenulumThickness.THIN,
  },
  {
    value: 'THICK',
    label: 'reports.anatomyExam.frenulumThickness.thick',
    src: thick,
    count: anatomyExamScoreMap.frenulumThickness.THICK,
  },
];

export const frenulumTongueAttach = [
  {
    value: 'MIDLINE',
    label: 'reports.anatomyExam.frenulumTongueAttach.midline',
    src: frenulumAttachMidline,
    count: anatomyExamScoreMap.frenulumTongueAttach.MIDLINE,
  },
  {
    value: 'MIDLINE_APEX',
    label: 'reports.anatomyExam.frenulumTongueAttach.midlineApex',
    src: midlineApex,
    count: anatomyExamScoreMap.frenulumTongueAttach.MIDLINE_APEX,
  },
  {
    value: 'APEX',
    label: 'reports.anatomyExam.frenulumTongueAttach.apex',
    src: apex,
    count: anatomyExamScoreMap.frenulumTongueAttach.APEX,
  },
];

export const frenulumFloorAttach = [
  {
    value: 'SUBLINGUAL_CARUNCLES',
    label: 'reports.anatomyExam.frenulumFloorAttach.sublingualCaruncles',
    src: sublingualCaruncles,
    count: anatomyExamScoreMap.frenulumFloorAttach.SUBLINGUAL_CARUNCLES,
  },
  {
    value: 'INFERIOR_ALVEOLAR',
    label: 'reports.anatomyExam.frenulumFloorAttach.inferiorAlveolar',
    src: inferiorAlveolar,
    count: anatomyExamScoreMap.frenulumFloorAttach.INFERIOR_ALVEOLAR,
  },
];
