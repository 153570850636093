import React from 'react';
import styled from 'styled-components';
import { Form, Radio as _Radio, RadioChangeEvent } from 'antd';

const Radio = styled(_Radio)`
  &.ant-radio-wrapper {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
  }
`;

type Props = {
  name: string;
  options: { value: string; name: React.ReactElement }[];
  onChange: (e: RadioChangeEvent) => void;
  value?: string;
};

const RadioGroup: React.FC<Props> = ({ name, options, onChange, value }) => {
  return (
    <Form.Item>
      <Radio.Group name={name} onChange={onChange} value={value} style={{ width: '100%' }}>
        {options.map(({ value, name }) => (
          <Radio key={value} value={value}>
            {name}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioGroup;
