import { DownloadOutlined } from '@ant-design/icons';
import { List, Typography, Button } from 'antd';
import React, { ReactNode, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { pdf } from '@react-pdf/renderer';

import { Patient, Report } from 'modules/Main/Patients/types';
import { REPORT_META, getAvailableReports, downloadReport } from '../report-utils';

interface PatientReportsProps {
  patient: Patient;
  patientId: string;
}

interface State {
  [key: string]: boolean;
}

enum Actions {
  SET_LOADING = 'loading',
  SET_LOADED = 'loaded',
}

function downloadingReducer(state: State, action: { type: Actions; report: string }): State {
  switch (action.type) {
    case Actions.SET_LOADING:
      return { ...state, [action.report]: true };
    case Actions.SET_LOADED:
      return { ...state, [action.report]: false };
    default:
      return state;
  }
}

const initialState = {
  [Report.SCREENING]: false,
  [Report.COMPLETE_PROTOCOL]: false,
};

const PatientReports: React.FC<PatientReportsProps> = ({ patient }) => {
  const [downloadingState, dispatch] = useReducer(downloadingReducer, initialState);
  const [t] = useTranslation();
  const reports = getAvailableReports(patient);

  const generateReport = (type: Report, fileName: string) => async (): Promise<void> => {
    dispatch({ type: Actions.SET_LOADING, report: type });
    const ReportComponent = REPORT_META[type].component;

    const document = <ReportComponent patient={patient} />;

    const blobPdf = pdf(document);

    // We need to call update container due to an issue to this release
    // https://github.com/diegomura/react-pdf/issues/932
    blobPdf.updateContainer(document);

    const blob = await blobPdf.toBlob();

    downloadReport(blob, fileName);

    dispatch({ type: Actions.SET_LOADED, report: type });
  };

  return (
    <>
      <Typography.Title level={3}>{t('actions.reports')}</Typography.Title>
      <List
        dataSource={reports}
        renderItem={(item: Report): ReactNode => {
          const reportName = t(REPORT_META[item].i18nKey);
          const fileName = `${patient.name} - ${reportName}.pdf`;

          return (
            <List.Item key={item}>
              <List.Item.Meta title={reportName} />

              <Button
                type="default"
                icon={<DownloadOutlined />}
                loading={downloadingState[item]}
                onClick={generateReport(item, fileName)}
              >
                {t('actions.download')}
              </Button>
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default PatientReports;
