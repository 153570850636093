import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

const Container = styled.div`
  margin: 18px;
  padding: 20px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const MaxWidthContainer = styled.div`
  max-width: 256px;
  margin: auto;
`;

const Price = styled(Text)`
  display: block;
  margin-left: 18px;
  font-size: 24px;

  &.ant-typography {
    ${({ delete: striked }): string => (striked ? 'color: lightgrey;' : ``)};
  }
`;

const Savings = styled(Text)`
  text-align: center;
  margin-bottom: 18px;

  &.ant-typography {
    color: green;
  }
`;

const SavingsContainer = styled.div`
  margin-top: 18px;
  text-align: center;
`;

interface Props {
  originalPrice: string;
  price: string;
  currency: string;
  isCouponValid: boolean;
  amountOff: string;
}

const PriceDetails: React.FC<Props> = ({ price, currency, originalPrice, isCouponValid, amountOff }) => {
  const [t] = useTranslation();

  return (
    <Container>
      <MaxWidthContainer>
        {isCouponValid ? (
          <>
            <Price strong delete>
              {t('plan.priceDetails.monthlyPrice', {
                currency: t(`plan.priceDetails.${currency}`),
                price: originalPrice,
              })}
            </Price>
            <Price strong>
              {t('plan.priceDetails.monthlyPrice', { currency: t(`plan.priceDetails.${currency}`), price })}
            </Price>
            <SavingsContainer>
              <Savings strong>
                {t('plan.priceDetails.amountOff', { currency: t(`plan.priceDetails.${currency}`), amountOff })}
              </Savings>
            </SavingsContainer>
          </>
        ) : (
          <Price strong>
            {t('plan.priceDetails.monthlyPrice', { currency: t(`plan.priceDetails.${currency}`), price })}
          </Price>
        )}
      </MaxWidthContainer>
    </Container>
  );
};

export default PriceDetails;
