import YouTube from 'components/YouTube';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  margin-left: auto;
  padding: 24px 16px;
  max-width: 460px;
`;

const Features = styled.div`
  margin-top: 16px;
  padding: 24px;

  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
`;

const Description: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Container>
      <h1>{t('summary.title')}</h1>

      <YouTube videoId={t('summary.videoId')} />

      <Features>{t('summary.features.patients')}</Features>
      <Features>{t('summary.features.exams')}</Features>
      <Features>{t('summary.features.reports')}</Features>
    </Container>
  );
};

export default Description;
