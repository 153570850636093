import React from 'react';
import { Input, InputProps } from 'antd';
import Mask from 'react-input-mask';

type Props = {
  mask: string;
} & InputProps;

const MaskedInput: React.FC<Props> = ({ mask, ...otherProps }) => {
  return (
    <Mask mask={mask} {...otherProps}>
      {inputProps => <Input {...inputProps} />}
    </Mask>
  );
};

export default MaskedInput;
