import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { useTranslation } from 'react-i18next';

export type CompleteReportHeaderProps = {
  examTitle?: string;
  partTitle?: string;
};

const CompleteReportHeader: React.FC<CompleteReportHeaderProps> = ({ examTitle, partTitle }) => {
  const [t] = useTranslation();

  return (
    <>
      <View style={{ textAlign: 'center', marginBottom: 12 }}>
        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 12 }}>{t('reports.complete.title')}</Text>
        <Text>{t('reports.screening.author')}</Text>
      </View>
      {examTitle && (
        <View style={{ textAlign: 'center', marginBottom: 12 }}>
          <Text style={{ border: '1px solid black', fontSize: 12, fontFamily: 'Helvetica', padding: 4 }}>
            {examTitle}
          </Text>
        </View>
      )}
      {partTitle && (
        <View style={{ marginBottom: 12 }}>
          <Text style={{ fontSize: 12, fontFamily: 'Helvetica', padding: 4 }}>{partTitle}</Text>
        </View>
      )}
    </>
  );
};

export default CompleteReportHeader;
