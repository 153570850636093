import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Row, Input, Select, Typography } from 'antd';
import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { propEq, propOr } from 'ramda';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

const { Title, Text } = Typography;

import ROUTES from 'routes';
import { handleInputChange, handleSelectChange } from 'utils/formik';

const MarginBottomBlock = styled.div`
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export type SearchValues = {
  term: string;
  filterBy: string;
};

type Props = {
  onSubmit: (values: SearchValues) => Promise<void>;
};

export const ListHeader: React.FC<Props> = ({ onSubmit }) => {
  const [t] = useTranslation();
  const isExtraSmall = useMedia('(max-width: 480px)');

  const initialValues: SearchValues = { term: '', filterBy: 'name' };
  const [currentSearch, setCurrentSearch] = useState(initialValues);

  const formik = useFormik({
    initialValues,
    onSubmit: async values => {
      setCurrentSearch(values);
      await onSubmit(values);
    },
    onReset: (_, formik) => {
      setCurrentSearch(initialValues);
      formik.submitForm();
    },
  });

  const filterOptions = [
    { value: 'name', label: t('patient.searchFilters.name') },
    { value: 'parentName', label: t('patient.searchFilters.parentName') },
    {
      value: 'parentNationalId',
      label: t('patient.searchFilters.parentNationalId'),
    },
  ];

  const findFilterOption = (filterBy: string): string =>
    propOr('', 'label', filterOptions.find(propEq('value', filterBy)));

  return (
    <>
      <Container>
        <Title level={3}>{t('patient.list')}</Title>
        <Link to={ROUTES.PATIENTS_ADD}>
          {isExtraSmall ? null : (
            <Button type="primary" icon={<UserAddOutlined />}>
              {t('patient.add')}
            </Button>
          )}
        </Link>
      </Container>

      <Form onFinish={formik.handleSubmit}>
        {isExtraSmall ? (
          <MarginBottomBlock>
            <Row gutter={16}>
              <Col xs={{ span: 24 }}>
                <Link to={ROUTES.PATIENTS_ADD}>
                  <Button block type="primary" icon={<UserAddOutlined />}>
                    {t('patient.add')}
                  </Button>
                </Link>
              </Col>
            </Row>
          </MarginBottomBlock>
        ) : null}
        <Row gutter={16}>
          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
            <Form.Item>
              <Input
                placeholder={t('common.search')}
                onChange={handleInputChange('term', formik)}
                onBlur={formik.handleBlur}
                value={formik.values.term}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
            <Form.Item>
              <Select
                options={filterOptions}
                onChange={handleSelectChange('filterBy', formik)}
                onBlur={formik.handleBlur}
                value={formik.values.filterBy}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                icon={<SearchOutlined />}
                disabled={!formik.isValid || !formik.dirty}
                loading={formik.isSubmitting}
                block
              >
                {t('common.search')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {currentSearch.term && (
          <>
            <Text>
              {t('common.searchInfo', {
                term: currentSearch.term,
                filterBy: findFilterOption(currentSearch.filterBy),
              })}
            </Text>
            <Button type="link" onClick={formik.handleReset}>
              {t('common.resetSearch')}
            </Button>
          </>
        )}
      </Form>
    </>
  );
};
