import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

const Title = styled.h2`
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 8px;
`;

type Props = {
  retryFunction?: () => void;
  message?: string;
  loading?: boolean;
};

export const InlineErrorMessage: React.FC<Props> = ({ message }) => {
  const [t] = useTranslation();

  return (
    <div>
      <Title>{t('errorMessage.title')}</Title>
      <div>{message || t('errorMessage.defaultMessage')}</div>
    </div>
  );
};
